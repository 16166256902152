import ActiveRecord from 'models/active_record/base'
import {date} from 'initializers/date'
import {DateRange} from 'moment-range'
import {makeRange} from 'helpers/date'

class User extends ActiveRecord {
	static tableName = 'users'

	salary(dateRange) {
		const {salaries} = this
		if (!salaries) return null
		if (!dateRange) return null
		if (typeof dateRange != DateRange) return salaries.find(c => c.dateRange.contains(dateRange))
		return salaries.find(c => c.dateRange.overlaps(dateRange))
	}

	birthday() {
		return this.verjaardag && date(this.verjaardag)
	}

	nextBirthday() {
		const birthday = this.birthday()
		if (!birthday) return

		const currentYear = date().year()
		let nextBday = birthday.clone().year(currentYear)

		const today = date()

		if (nextBday.format() == today.format()) {
			return today
		}

		if (nextBday.isBefore(today)) {
			nextBday.add(1, 'years')
		}

		return nextBday
	}

	address() {
		const city = this.stad
		const street = this.straat
		const number = this.huisnummer
		const postal_code = this.postcode

		let address = ''
		if (street) address += street
		if (street && number) address += ` ${number}`
		if (street && number && city) address += ','
		if (city) address += ` ${city}`
		if (city && postal_code) address += ` ${postal_code}`

		return address
	}

	previousBirthday() {
		const nextBday = this.nextBirthday()
		if (!nextBday) return
		return nextBday.clone().subtract(1, 'years')
	}

	age() {
		const birthday = this.birthday()
		if (!birthday) return

		const today = date()
		const age = today.diff(birthday, 'years')

		// Don't show or use ages which are guaranteed to be false.
		if (age > 100) return

		return age
	}

	findContractPart(part, dateRange) {
		const parts = this[part]
		if (!parts || !dateRange) return null
		if (typeof dateRange != DateRange) return parts.find(p => p.dateRange.contains(dateRange))
		return parts.find(c => c.dateRange.overlaps(dateRange))
	}

	contractHolder = dateRange => this.findContractPart('contractHolders', dateRange)
	salary = dateRange => this.findContractPart('salaries', dateRange)
	userEmploymentType = dateRange => this.findContractPart('userEmploymentTypes', dateRange)
	workSchedule = dateRange => this.findContractPart('workSchedules', dateRange)

	currentContractHolder = () => this.findContractPart('contractHolders', todayDateRange())
	currentSalary = () => this.findContractPart('salaries', todayDateRange())
	currentUserEmploymentType = () => this.findContractPart('userEmploymentTypes', todayDateRange())
	currentWorkSchedule = () => this.findContractPart('workSchedules', todayDateRange())

	findNearestContractPart(part) {
		const parts = this[part]?.sortBy('start_date')
		if (_.isEmpty(parts)) return

		const activePart = this.findContractPart(part, todayDateRange())
		if (activePart) return activePart

		const now = date()
		const futurePart = _.first(parts.filter(part => date(part.start_date).isAfter(now)))
		if (futurePart) return futurePart

		const pastPart = _.last(parts)
		if (pastPart) return pastPart
	}

	nearestContractHolder = () => this.findNearestContractPart('contractHolders')
	nearestSalary = () => this.findNearestContractPart('salaries')
	nearestUserEmploymentType = () => this.findNearestContractPart('userEmploymentTypes')
	nearestWorkSchedule = () => this.findNearestContractPart('workSchedules')
}

const todayDateRange = () => {
	const today = date().format()
	return makeRange(today, today)
}

export default User
