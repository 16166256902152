import {buildColumn, LARGE_CELL_WIDTH, XLARGE_CELL_WIDTH, XXLARGE_CELL_WIDTH} from 'common/components/advanced_table'
import {date} from 'initializers/date'
import {t} from 'initializers/i18n'

const managerRole = {
	enableRole: 'manager',
	roleEnv: user => user.environment_ids,
}

export const PERSONAL_ID_COLUMNS = [
	{
		accessorKey: 'full_name',
		width: XXLARGE_CELL_WIDTH,
	},
	{
		accessorKey: 'email',
		width: XLARGE_CELL_WIDTH,
		...managerRole,
	},
]

const contractRoleForHolder = {
	enableRole: 'contracten',
	roleEnv: user => user.nearestContractHolder()?.environment_id,
}
const contractRoleForSalary = {
	enableRole: 'contracten',
	roleEnv: user => user.nearestSalary()?.environment_id,
}

export const PERSONAL_DETAIL_COLUMNS = [
	{
		accessorKey: 'telefoonnummer',
		...managerRole,
	},
	{
		id: 'address',
		accessorFn: user => user.address(),
		width: XLARGE_CELL_WIDTH,
		defaultVisible: false,
		...managerRole,
	},
	{
		id: 'age',
		accessorFn: user => user.age(),
		...managerRole,
	},
	{
		id: 'next_birthday',
		accessorFn: user => user.nextBirthday(),
		cell: 'DateCell',
		defaultVisible: false,
	},
	{
		id: 'birthday',
		accessorFn: user => user.verjaardag,
		cell: 'DateCell',
		...managerRole,
	},
]

export const CONTRACT_COLUMNS = [
	{
		// This is the only contract based column that always looks to the current contract, instead of the nearest contract.
		id: 'active_contract',
		accessorFn: user => !!user.currentContractHolder(),
		cell: 'BooleanCell',
	},
	{
		id: 'contract_environment_id',
		accessorFn: user => user.nearestContractHolder()?.environment_id,
		cell: 'EnvironmentCell',
		defaultVisible: false,
	},
	{
		id: 'contract_start_date',
		accessorFn: user => user.nearestContractHolder()?.start_date,
		cell: 'DateCell',
		defaultVisible: false,
		...contractRoleForHolder,
	},
	{
		id: 'contract_end_date',
		accessorFn: user => user.nearestContractHolder()?.end_date,
		cell: 'DateCell',
		defaultVisible: false,
		...contractRoleForHolder,
	},
	{
		id: 'contract_type',
		accessorFn: user => {
			const name = user.nearestUserEmploymentType()?.name
			return name ? t(name) : null
		},
		// EmploymentType has no env ID, use holder instead
		...contractRoleForHolder,
	},
	{
		id: 'contract_hours',
		accessorFn: user => user.nearestWorkSchedule()?.total_mins,
		cell: 'TimeCell',
		// currentWorkSchedule doesn't return ID when there is no schedule, always show disabled area instead of only when there is no salary
		...contractRoleForHolder,
	},
	{
		id: 'gross_hourly_wage',
		accessorFn: user => user.nearestSalary()?.amount,
		cell: 'MoneyCell',
		defaultVisible: false,
		...contractRoleForSalary,
	},
	{
		id: 'hourly_labor_costs',
		accessorFn: user => user.cost_per_hour,
		cell: 'MoneyCell',
		defaultVisible: false,
		...contractRoleForSalary,
	},
]

export const columns = [
	...PERSONAL_ID_COLUMNS,
	{
		id: 'teams',
		accessorFn: getTeamNames,
		defaultVisible: false,
		collapsibleCell: true,
	},
	{
		id: 'environments',
		accessorFn: getEnvNames,
		defaultVisible: false,
		collapsibleCell: true,
	},
	...PERSONAL_DETAIL_COLUMNS,
	...CONTRACT_COLUMNS,
	{
		accessorKey: 'vloer_code',
		defaultVisible: false,
		...managerRole,
	},
	{
		id: 'activated',
		accessorKey: 'confirmed',
		cell: 'BooleanCell',
		defaultVisible: false,
		...managerRole,
	},
	{
		id: 'in_service_date',
		accessorKey: 'date_started',
		cell: 'DateCell',
		defaultVisible: false,
		...managerRole,
	},
	{
		id: 'created_at',
		accessorFn: user => {
			const year = date(user.created_at).year()
			// Guard against non sensical created at dates, some users are supposedly created in the year 1.
			if (year < 2000) return null
			return user.created_at
		},
		cell: 'DateCell',
		defaultVisible: false,
		...managerRole,
	},
].map(buildColumn)

// Below: use function declarations instead of expressions because they're not hoisted.

function getEnvNames(user) {
	// .filter(Boolean) against race condition in which another user creates records which this user does not have locally yet.
	const envNames = user.teams?.map(team => team.environment?.naam).filter(Boolean)
	if (!envNames) return null
	return envNames.uniq().sort().join(', ')
}

function getTeamNames(user) {
	const teams = user.teams
	if (!teams) return ''
	return teams._map('naam').uniq().sort().join(', ')
}
