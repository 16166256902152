import {mergeAttributes} from '@tiptap/core'
import TiptapLink from '@tiptap/extension-link'
import {Plugin} from '@tiptap/pm/state'

export const Link = TiptapLink.extend({
	inclusive: false,

	parseHTML() {
		return [{tag: 'a[href]:not([data-type="button"]):not([href *= "javascript:" i])'}]
	},

	renderHTML({HTMLAttributes}) {
		return ['a', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {class: 'link'}), 0]
	},

	addProseMirrorPlugins() {
		const {editor} = this

		return [
			...(this.parent?.() || []),
			new Plugin({
				props: {
					handleKeyDown: (view, event) => {
						const {empty, to} = editor.state.selection

						if (event.key === 'Escape' && empty !== true) {
							editor.commands.focus(to, {scrollIntoView: false})
						}

						return false
					},
				},
			}),
		]
	},
})
