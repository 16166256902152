import {Layout} from 'common/components'
import {TextEditorButton} from '../text_editor_button'
import {SavingIndicator} from './saving_indicator'
import {useRichTextEditorContext} from 'common/components/rich_text_editor'

export const DocumentMenuBar = () => {
	const {readOnly} = useRichTextEditorContext()

	if (readOnly) return null

	return (
		<Layout className="document-menu-bar" horizontal="spaceBetween" padding="4" borderBottom initialBackground={Colors.lightGrey} wrap>
			<Layout>
				<TextEditorButton commandNames={['paragraph', 'heading1', 'heading2', 'heading3']} type="textOnly" />
				<TextEditorButton commandNames="bold" />
				<TextEditorButton commandNames="underline" />
				<TextEditorButton commandNames="italic" />
				<TextEditorButton commandNames="strike" />
				<TextEditorButton commandNames="textStyle" />
				<TextEditorButton commandNames="highlight" />
				<TextEditorButton commandNames="textAlign" />
				<TextEditorButton
					t="more"
					commandNames={[
						'bulletList',
						'orderedList',
						'image',
						'youtube',
						'link',
						'table',
						'horizontalRule',
						'blockquote',
						'two_columns',
						'three_columns',
					]}
					type="textOnly"
				/>
			</Layout>
			<Layout>
				<SavingIndicator />
				<TextEditorButton commandNames="undo" />
				<TextEditorButton commandNames="redo" />
			</Layout>
		</Layout>
	)
}
