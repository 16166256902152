import {ModalWithNewForm} from 'components/form/new_modal_form'
import {Input} from '@eitje/form-fields-web'
import {t} from 'initializers/i18n'
import {ListPicker, Message} from 'common/components'
import {NAMESPACE} from 'common/components/rich_text_editor'

const SIZE_MAP = {
	small: {width: 480},
	medium: {width: 640},
	large: {width: 720},
}

const sizes = Object.keys(SIZE_MAP)

export const YouTubeFormModal = ({initialValues = {}, onClose, onSubmit}) => {
	const ns = `${NAMESPACE}.youtube`
	const sizeOptions = sizes.map(size => ({id: size, label: t(`${NAMESPACE}.sizes.${size}`)}))
	const {src, width} = initialValues
	const size = getSizeByDimensions(width)
	const values = {src, size}

	const handleSubmit = ({src, size}) => {
		const width = SIZE_MAP[size].width
		const height = calculateHeight(width)
		onSubmit({src, width, height})
	}

	const modalProps = {
		goBack: onClose,
		onCancel: onClose,
	}

	return (
		<ModalWithNewForm name={ns} width={450} initialValues={values} onSubmit={handleSubmit} modalProps={modalProps} oneFieldPerFormRow>
			<Message t={`${ns}.message`} colorSet="color-bordered-outline" />
			<Input field="src" required />
			<ListPicker field="size" items={sizeOptions} dropdown required labelField="label" single noSort />
		</ModalWithNewForm>
	)
}

function getSizeByDimensions(width) {
	return sizes.find(key => SIZE_MAP[key].width === width) || 'large'
}

function calculateHeight(width) {
	return (width / 16) * 9
}
