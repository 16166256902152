import {useEffect} from 'react'
import {usePlanningContext, useEnvsContext, useConflictedShifts} from 'cores/planning/index'
import {useHasRole} from 'hooks'
import {t} from 'initializers/i18n'
import {EitjeButton} from 'common/components'
import {useFilterFooterContext} from 'common/components/filter_footer/context'

export const ConflictsButton = () => {
	const {dateRange} = usePlanningContext()
	const {filteredItems: filteredShifts} = useFilterFooterContext()
	const {conflictFilter, setConflictFilter, toggleConflictFilter} = useEnvsContext()
	const className = conflictFilter && 'active'

	const conflictedShifts = useConflictedShifts(filteredShifts, dateRange)

	useEffect(() => {
		if (conflictedShifts.length == 0) {
			setConflictFilter(false)
		}
	}, [conflictedShifts])

	const hasManagerRole = useHasRole('manager')
	if (conflictedShifts.length == 0 || !hasManagerRole) return null

	return (
		<EitjeButton
			colorSet="color-bordered-fill"
			baseColor="red"
			iconLeft={conflictFilter ? 'eye-closed' : 'eye-open'}
			className={className}
			onClick={toggleConflictFilter}
		>
			{t('planning.footer.conflicts')}: {conflictedShifts.length}
		</EitjeButton>
	)
}
