import {BaseFloatingMenu} from './base_floating_menu'
import {Layout, Text} from 'common/components'

export const BarFloatingMenu = ({name, extLink, children}) => {
	const padding = extLink ? '4 4 4 12' : '4'
	const gap = extLink ? 12 : 4

	return (
		<BaseFloatingMenu name={name}>
			<Layout className="bar-floating-menu" padding={padding} gap={gap} initialBackground={Colors.lightGrey} borderRadius border>
				{extLink && (
					<>
						<Layout grow extLink={extLink}>
							<Text className="no-first-letter-uppercase" semiBold truncate truncateTooltip={false}>
								{extLink}
							</Text>
						</Layout>
						<Layout>{children}</Layout>
					</>
				)}
				{!extLink && children}
			</Layout>
		</BaseFloatingMenu>
	)
}
