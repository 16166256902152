import {t} from 'initializers/i18n'
import {useAvContext} from 'contexts/availability'
import {useUserContext} from '../index'
import {loadTemplate} from 'cores/availability/actions'
import {ModalLink, Link} from 'components/routing'
import useUserWeek from 'cores/availability/hooks/use_user_week'
import WeekShifts from 'cores/availability/components/week_shifts'
import useShared from 'hooks/use_shared'
import {ScheduleSummary} from 'cores/contract/index'
import {useEnvContext} from 'cores/availability/pages/index/env_table/contexts/env'
import '../../../styles/availability.less'
import './components.less'
import utils from '@eitje/utils'

export const dropdownElements = () => {
	const {hasContractRole} = useShared()
	const {user} = useUserContext()
	const {startDate, dateRange} = useAvContext()
	const cont = hasContractRole && user.contractHolder(dateRange)

	const elements = [
		<LoadTemplate />,
		<Link t="viewTemplate" to={`/user/${user.id}/profile/availability_template`} />,
		cont && <ModalLink t="viewContract" to={`/forms/contracts/${cont.id}/details`} />,
		<div className="row align-items-center gap-md">
			<p>{t('amtShiftPerWeek')}</p>
			<WeekShifts date={startDate} userId={user.id} />
		</div>,
	].filter(Boolean)

	return elements
}

const LoadTemplate = () => {
	const {dateRange} = useAvContext()
	const {userAvShifts, user} = useUserContext()
	const _loadTemplate = () => {
		loadTemplate({user_id: user.id, avShifts: userAvShifts, dateRange})
	}
	return <p onClick={_loadTemplate}> {t('loadTemplate')} </p>
}

export const Subline = () => {
	const {dateRange, alwaysShowPrefShifts} = useAvContext()
	const {user} = useUserContext()
	const {workSchedules = []} = user
	const workSchedule = workSchedules.find(w => w.dateRange.overlaps(dateRange))

	if (!alwaysShowPrefShifts && workSchedule) return <ScheduleSummary schedule={workSchedule} />
	return <ShiftPrefs />
}

const ShiftPrefs = () => {
	const {env} = useEnvContext()
	const {startDate} = useAvContext()
	const {user} = useUserContext()
	const userWeek = useUserWeek({id: user.id, envId: env.id, date: startDate})
	const {preferred_shifts} = userWeek
	const str = utils.exists(preferred_shifts) ? t('shifts', {count: preferred_shifts}) : t('unknown')
	return <p className="eitje-text-secondary"> {str} </p>
}
