import {Node, ReactNodeViewRenderer} from '@tiptap/react'
import {YouTubeNodeView, LinkNodeView, ImageNodeView} from './content_node_views'

const NODE_TYPES = [
	{
		name: 'youtube',
		commandName: 'setYouTubeModal',
		NodeView: YouTubeNodeView,
	},
	{
		name: 'link',
		commandName: 'setLinkModal',
		NodeView: LinkNodeView,
	},
	{
		name: 'image',
		commandName: 'setImageUpload',
		NodeView: ImageNodeView,
	},
]

export const ContentPlaceholder = Node.create({
	name: 'content-placeholder',
	isolating: true,
	defining: true,
	group: 'block',
	inline: false,
	draggable: true,
	selectable: true,

	addAttributes() {
		return {
			type: {
				parseHTML: element => element.getAttribute('data-type').replace(`${this.name}-`, ''),
				renderHTML: attributes => ({
					'data-type': attributes.type,
				}),
			},
			data: {},
		}
	},

	parseHTML() {
		return NODE_TYPES.map(({name: type}) => ({tag: `div[data-type="${this.name}-${type}"]`}))
	},

	renderHTML() {
		return ['div', {'data-type': this.name}]
	},

	addCommands() {
		const insertCommand =
			type =>
			() =>
			({commands, state}) => {
				const {from, to} = state.selection

				commands.insertContent({
					type: this.name,
					attrs: {
						type,
						data: state.doc.textBetween(from, to),
					},
				})
			}

		return NODE_TYPES.reduce((acc, {name, commandName}) => {
			acc[commandName] = insertCommand(name)
			return acc
		}, {})
	},

	addNodeView() {
		return props => {
			const {type} = props.node.attrs
			const NodeView = NODE_TYPES.find(({name}) => name === type)?.NodeView

			if (NodeView) return ReactNodeViewRenderer(NodeView)(props)

			return null
		}
	},
})
