import {useCallback, useState} from 'react'

export const useFloatingMenu = () => {
	const [showEdit, setShowEdit] = useState(false)

	const handleEdit = useCallback(() => {
		setShowEdit(true)
	}, [])

	const handleClose = useCallback(() => {
		setShowEdit(false)
	}, [])

	return {showEdit, handleEdit, handleClose}
}
