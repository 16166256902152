import {UsersInServiceAdvancedTable} from 'common/components/advanced_table/instances/users_in_service'
import SearchProvider from 'contexts/search'
import {FilterFooter, FilterFooterProvider, PageLayout} from 'common/components'
import {useMultiEdit, useHasRole, useActiveUsers, useShared} from 'hooks'
import {User} from 'models'
import {PageHeader} from './page_header'
import {contractJoins} from 'constants/users'
import {useWhere} from '@eitje/easy_api'
import {t} from 'initializers/i18n'

export const contractTypeFilterObject = {
	name: 'contract_type',
	items: ['Nul uren', 'Uren contract', 'Payroll', 'ZZP', 'no_contract'],
	labelT: label => t(label),
	filterFunction: (user, opts) => {
		if (!opts.length) return true // always include the user when no filter is set
		const type = user.currentUserEmploymentType()?.name
		if (!type && opts.includes('no_contract')) return true
		return type && opts.includes(type)
	},
}

export const UsersInService = ({}) => {
	User.doIndex({params: {new: true}})
	const users = useActiveUsers()
	const opts = {joins: ['teams', {teams: 'environments'}, ...contractJoins.joins]}
	let items = useWhere('users', users.ids(), opts)
	const multiEditProps = useMultiEdit()

	// Filter teams on being in the current org, since 1. we return non org teams from the BE to interpolate names in the cross org
	// chat page and 2. we can't use hooks within a column definition, so have to manipulate the input data instead.
	const {orgEnvIds} = useShared()
	items = items.map(user => {
		const teams = user.teams.filter(team => orgEnvIds.includes(team.environment_id))
		return {...user, teams}
	})

	// Ideally disable + tooltip instead of hiding, but currently not possible because role logic is not present in the component lib' Layout
	const contractRole = useHasRole('contracten')
	const filters = ['environment', 'team', contractRole && contractTypeFilterObject]

	return (
		<SearchProvider>
			<FilterFooterProvider id="users_in_service" items={items} filters={filters} searchField="full_name">
				<PageLayout state={multiEditProps} name="active-users" headers={<PageHeader />} footers={<FilterFooter />}>
					<UsersInServiceAdvancedTable />
				</PageLayout>
			</FilterFooterProvider>
		</SearchProvider>
	)
}
