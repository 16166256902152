import utils from '@eitje/web_utils'
import {MultiAction, ShiftLink, CheckInPopoverWrapper} from 'cores/time_registration'
import {TABLE_INFO} from './constants'
import {columns} from './columns'
import {useAdvancedTable, AdvancedTable, getSelectedRows} from 'common/components/advanced_table'
import {useFilterFooterContext, CreateButton} from 'common/components'

export * from './constants'

export const TimeRegistrationAdvancedTable = () => {
	const {filteredItems: shifts, filteredOutAmount, clearFilters} = useFilterFooterContext()

	const tableData = useAdvancedTable({
		data: shifts,
		initialPinning: ['user_id', 'status', 'date'],
		secondarySort: 'date',
		columns,
		TABLE_INFO,
		clearFilters,
		filteredOutAmount,
	})

	const selectedRows = getSelectedRows(tableData)
	const selectedShifts = selectedRows.map(r => r.original)

	const placeholderProps = {
		animation: 'clock',
		name: 'hours',
		supportFilmId: 'time_registration_table',
		docsId: '6712557',
		children: <CreateButton modalLink="/time_registration/new" t="action_button" />,
	}

	return (
		<>
			<AdvancedTable data={tableData} RowWrapper={RowWrapper} placeholderProps={placeholderProps} multiEdit />
			{selectedRows.length > 0 && <MultiAction close={() => tableData.resetRowSelection()} selectedShifts={selectedShifts} />}
		</>
	)
}

const RowWrapper = ({row, children}) => {
	const {original} = row
	if (utils.exists(row.subRows)) return children
	if (original.isCheckIn()) return <CheckInPopoverWrapper children={children} shift={original} />
	return (
		<ShiftLink shift={original} className="advanced-table-row-link">
			{children}
		</ShiftLink>
	)
}
