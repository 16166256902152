import {Icon} from '@eitje/web_components'
import {Layout, Text} from 'common/components'

export const ButtonBar = ({t, label, onClick, iconLeft, iconRight, loading, colorSet = 'grey-bordered', ...rest}) => {
	return (
		<Layout
			className="button-bar"
			padding="8 12"
			width="full"
			horizontal="spaceBetween"
			border
			borderRadius
			gap={0}
			height={40}
			onClick={onClick}
			colorSet={colorSet}
			{...rest}
		>
			<Layout gap={16}>
				{iconLeft && <Icon name={iconLeft} />}
				<Text semiBold truncate truncateTooltip={false} t={t}>
					{label}
				</Text>
			</Layout>
			<IconRight loading={loading} iconRight={iconRight} onClick={onClick} />
		</Layout>
	)
}

const IconRight = ({loading, iconRight, onClick}) => {
	if (loading) return <Icon rotatingAnimation name="arrow-circle-right" />
	if (typeof iconRight === 'string') return <Icon name={iconRight} />
	if (iconRight) return iconRight
	if (onClick) return <Icon name="arrow-small-right" />
	return null
}
