import {getLanguage} from 'initializers/i18n'

export const getBaseUrl = () => {
	const lng = getLanguage()
	const docsBaseUrl = `https://help.eitje.app/${lng}`
	return docsBaseUrl
}

export const getUrl = collection => {
	const docsBaseUrl = getBaseUrl()
	const articleUrl = `${docsBaseUrl}/articles`
	const collectionUrl = `${docsBaseUrl}/collections`
	return collection ? collectionUrl : articleUrl
}

export const useDocsLink = ({collection, to, isLink}) => {
	let condOpts = {}

	if (collection) {
		const baseUrl = getUrl(collection)
		const href = to ? `${baseUrl}/${to}` : getBaseUrl()
		condOpts = {
			[isLink ? 'href' : 'externalLink']: href,
		}
	} else {
		condOpts = {
			onClick: () => window.Intercom('showArticle', to),
		}
	}

	return condOpts
}
