import React from 'react'

import {Text as TextComponent, Title as TitleComponent} from '@eitje/web_components'

export const Text = props => {
	return <TextComponent {...props} />
}

export const Title = props => {
	return <TitleComponent {...props} />
}

export const titleWithCount = (title, count) => {
	if (!count) return title
	return `${title} (${count})`
}
