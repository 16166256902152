import {Placeholder as PlaceholderComponent} from '@eitje/web_components'
import {EitjeButton, SupportButton, SupportFilmButton, TourButton, VideoButton} from 'common/components'
import {DocsButton} from '../docs_button'

export const Placeholder = props => {
	const {supportButton, docsId, supportFilmId, tourId, videoId, collection} = props
	const customButtons = [
		tourId && <TourButton tourId={tourId} />,
		supportFilmId && <SupportFilmButton id={supportFilmId} />,
		docsId && <DocsButton iconLeft="external-link" to={docsId} collection={collection} />,
		videoId && <VideoButton videoId={videoId} />,
		supportButton && <SupportButton />,
	]

	return <PlaceholderComponent customButtons={customButtons} {...props} />
}
