import TiptapYoutube from '@tiptap/extension-youtube'

import './styles/index.less'

export const YouTube = TiptapYoutube.extend({
	parseHTML() {
		return [
			{
				tag: 'div[data-youtube-video] iframe',
			},
			{
				tag: 'div.embed-responsive iframe',
			},
		]
	},
})
