import {Layout} from 'common/components'
import {Header, Document} from './components'
import {PdfViewerProvider} from './context'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import './styles/index.less'

const inlineStyles = {overflow: 'hidden', backgroundColor: Colors.white}

export const PdfViewer = ({pdf, dropdownItems}) => {
	return (
		<PdfViewerProvider pdf={pdf} dropdownItems={dropdownItems}>
			<Layout className="pdf-viewer" direction="vertical" border borderRadius gap={0} grow width="full" style={inlineStyles}>
				<Header />
				<Document />
			</Layout>
		</PdfViewerProvider>
	)
}
