import {useEffect, useLayoutEffect} from 'react'
import {PageLayout, Text, RichTextEditor, PdfViewer} from 'common/components'
import {useParams, useFileUpload} from 'hooks'
import {Info} from 'models'
import {ArticleProvider, useArticleContext} from 'cores/knowledge'
import {navigate} from 'components/routing'
import {PageHeader} from './page_header'

const bodyLayoutProps = {
	height: 'full',
	direction: 'vertical',
}

const Article = () => {
	const {article, editDisabled, updateArticle, isPDF, isRTE} = useArticleContext()
	const {id, content, pdf_content, updated_at, addPdfContent, updateReadCount} = article
	const {handleUploadClick, inputElement} = useFileUpload({onUpload: addPdfContent})

	const dropdownItems = [
		{
			icon: 'arrow-circle',
			disabled: editDisabled,
			element: (
				<>
					<Text t="replacePdf" />
					{inputElement}
				</>
			),
			onClick: handleUploadClick,
		},
	]

	useEffect(() => {
		updateReadCount()
	}, [])

	const handleSave = async content => {
		if (content) await updateArticle({content})
	}

	return (
		<PageLayout name="article" bodyLayoutProps={bodyLayoutProps} headers={<PageHeader />} unsetPagePadding bodyWidth={1000}>
			{isRTE && (
				<RichTextEditor
					key={id} // using key to force a rerender when the route changes
					initialContent={content}
					readOnly={!!editDisabled}
					onSave={handleSave}
					updatedAt={updated_at}
				/>
			)}
			{isPDF && <PdfViewer pdf={pdf_content} dropdownItems={dropdownItems} />}
		</PageLayout>
	)
}

export const ArticlePage = () => {
	const {id} = useParams()
	const article = Info.find(id)

	const noArticle = _.isEmpty(article)

	useLayoutEffect(() => {
		// if a user is on a /article/:id page and switches env
		// no article will be returned. In that case we redirect them.
		if (noArticle) return navigate('/topics')
	}, [noArticle])

	return (
		<ArticleProvider article={article}>
			<Article />
		</ArticleProvider>
	)
}
