import {API} from '@eitje/easy_api'
import {LegacyMultiForm as MultiForm} from '@eitje/form'
import {LegacyDatePicker as DatePicker} from '@eitje/form-fields-web'
import utils from '@eitje/utils'
import {Button} from 'antd'
import HourMinutePicker from 'components/form/hour_minute_picker'
import useList from 'components/general/list'
import {Label, makeMinsObj, useWorkDays} from 'helpers/schedule'
import {history} from 'index'
import Form from 'initializers/form'
import {t} from 'initializers/i18n'
import _ from 'lodash'
import React, {Fragment, useRef, useState} from 'react'
import '../styles/contract.less'
import {DeleteButton, EitjeButton} from 'common/components'

const ScheduleRow = ({isFirst, item, allowDelete, isActive}) => {
	const {start_date, end_date, id, workWeeks} = item
	const {total, afterChange} = useWorkDays(workWeeks)

	const labelClassName = isActive ? 'row-with-justify-between margin-bottom-sm' : 'row-with-justify-between'

	return (
		<div className="work-week-row">
			<div className={labelClassName}>
				<div>
					<p className="eitje-text-secondary"> {t('fromTill', {from: start_date, till: end_date, kind: 'workWeek'})} </p>
					<p>{t('hrsPerWeek', total)} </p>
				</div>

				{isActive && allowDelete && isFirst && <DeleteButton onDel={() => API.destroyMutation('workSchedule', id)} />}
			</div>

			{isActive && (
				<Fragment>
					<ScheduleForm onSubmit={data => API.updateMulti('work_weeks', data)} afterChange={afterChange} initialValues={workWeeks}>
						<Button style={{marginTop: 8}} type="primary" submitButton showAfterTouch>
							{t('submit')}
						</Button>
					</ScheduleForm>
				</Fragment>
			)}
		</div>
	)
}

const getFixedTypes = ({userEmpTypes, employmentTypes}) => {
	return userEmpTypes.filter(e => {
		const empType = employmentTypes.find(e2 => e2.id == e.employment_type_id)
		return empType?.fixed
	})
}

const findScheduleRange = empTypes => {
	if (!utils.exists(empTypes)) return {}
	const types = _.orderBy(empTypes, 'start_date', 'asc')
	return {start_date: types[0].start_date, end_date: types[types.length - 1].end_date}
}

export const Schedule = ({contract, fullContract}) => {
	const schedules = _.orderBy(fullContract.workSchedules, 'end_date', 'desc')
	const fixedUserEmpTypes = getFixedTypes(fullContract)
	const createFields = findScheduleRange(fixedUserEmpTypes)

	const itemProps = {contract, allowDelete: schedules.length > 1}

	const {list} = useList({items: schedules, def: schedules[0]?.id, ListItem: ScheduleRow, itemProps})

	return (
		<>
			<div fieldWrapper className="modal-form-field-area">
				<div className={'list-item-active-bg'}>{list}</div>

				{schedules.length === 0 && fixedUserEmpTypes.length > 0 && (
					<ScheduleMutation onSubmit={data => API.create('work_schedules', {...data, ...createFields})} contract={contract} />
				)}
			</div>

			{schedules.length > 0 && (
				<div className="modal-inner-footer gap-xss" fieldWrapper>
					<EitjeButton modalLink="mutation" t="createMutation" />
				</div>
			)}
		</>
	)
}

export const ScheduleMutation = ({contract, onSubmit = mutate}) => {
	const {workSchedule = {}} = contract

	const isMutation = !!workSchedule.id
	const _afterSubmit = isMutation ? history.goBack : () => {}

	const [touched, setTouched] = useState(false)
	const multiForm = useRef(null)
	const {total, afterChange} = useWorkDays()

	const _afterChange = (field, data, allFields) => {
		if (field == 'hours' && !touched) multiForm.current?.setValues(makeMinsObj(data))
	}

	return (
		<Form
			fieldProps={{bordered: false}}
			afterChange={_afterChange}
			afterSubmit={_afterSubmit}
			onSubmit={data =>
				onSubmit({
					...data,
					work_weeks_attributes: multiForm.current?.getParams(),
					user_id: contract.user_id,
					environment_id: contract.environment_id,
				})
			}
		>
			<div fieldWrapper className="modal-form-field-area">
				{isMutation && (
					<DatePicker
						placeholder={t('selectDate')}
						defaultPickerValue={workSchedule.start_date}
						disabledAfter={contract.end_date}
						disabledBefore={workSchedule.start_date}
						label="mutationDate"
						required
						field="start_date"
					/>
				)}

				<HourMinutePicker
					required
					value={total.mins}
					max={70}
					readOnly={touched}
					decimalSeparator=":"
					validateMessage={t('form.required')}
					validate={val => val > 0}
					label={t('contractHours')}
					extraLabel={t('amountHours')}
					field="hours"
				/>

				<div fieldWrapper className="work-week-row border-bottom">
					<ScheduleForm innerRef={multiForm} formProps={{afterTouch: () => setTouched(true)}} afterChange={afterChange} />
				</div>
			</div>

			<div className="modal-inner-footer gap-xss" fieldWrapper>
				{isMutation && <Button onClick={history.goBack}> {t('cancel')} </Button>}
				<Button type="primary" style={{marginLeft: 4}} submitButton showAfterTouch>
					{t('save')}
				</Button>
			</div>
		</Form>
	)
}

const ScheduleForm = ({innerRef, children, readOnly, ...props}) => (
	<MultiForm ref={innerRef} {...props}>
		<Label field="even week" />
		<div className="row gap-xss" fieldWrapper>
			{utils.weekdays.map(d => (
				<HourMinutePicker className="default-field" readOnly={readOnly} max={14} labelVisible={false} field={d} />
			))}
		</div>
		{children}
	</MultiForm>
)

const mutate = data => API.arbitrary('work_schedules', 'mutation', data)
