import {useWhere} from '@eitje/easy_api'
import {Association, User} from 'models'
import {roleOrgEnvsSelector} from 'selectors'
import useSelector from './use_selector'
import useShared from './use_shared'

export const useRoleOrgEnvs = role => useSelector(roleOrgEnvsSelector, role)

export const useRoleOrgEnvsUsers = role => {
	const envs = useRoleOrgEnvs(role)
	const users = useWhere('users', {environment_ids: envs.ids()})
	return users
}

export const useRoleOrgEnvsUsersWithDel = (role, extra = {}) => {
	const {orgEnvIds} = useShared()
	const envs = useRoleOrgEnvs(role)
	const users = User.where({environment_ids: envs.ids()}, extra)
	const deletedUsers = User.whereNot({environment_ids: orgEnvIds}, extra).where({del_env_ids: envs.ids()})
	return new Association([...users, ...deletedUsers].uniq())
}
