import {Layout, Title} from 'common/components'
import {useChatWindowContext} from 'cores/chat'
import {IconButton} from './icon_button'

export const BaseHeader = ({title, unreadCount, actions}) => {
	const {closeChatWindow} = useChatWindowContext()

	return (
		<Layout padding={12} horizontal="spaceBetween" borderBottom>
			<Title truncate truncateTooltip={false} countSuffix={unreadCount}>
				{title}
			</Title>
			<Layout gap={4}>
				{actions}
				<IconButton name="cross" onClick={closeChatWindow} />
			</Layout>
		</Layout>
	)
}
