import {Node} from '@tiptap/core'
import './styles/index.less'

export * from './command'

export const Columns = Node.create({
	name: 'columns',
	group: 'block',
	content: 'column+',
	defining: true,
	isolating: true,

	addAttributes() {
		return {
			span: {
				default: 6,
				parseHTML: element => parseInt(element.getAttribute('data-span')) || 6,
				renderHTML: attributes => ({
					'data-span': attributes.span,
					class: `column column-${attributes.span}`,
				}),
			},
		}
	},

	addCommands() {
		return {
			setColumns:
				cols =>
				({commands}) => {
					const className = `column-${12 / cols}`
					const columns = [`<div class="column ${className}" data-type="column"><p></p></div>`]
						.reduce((res, current) => [...res, ...Array(cols).fill(current)], [])
						.join('')
					return commands.insertContent(`<div class="grid" data-type="columns">${columns}</div>`)
				},
		}
	},

	renderHTML() {
		return ['div', {class: 'grid', 'data-type': 'columns'}, 0]
	},

	parseHTML() {
		return [
			{
				tag: 'div.grid',
			},
		]
	},
})

export const Column = Node.create({
	name: 'column',
	group: 'block',
	content: 'block+',
	isolating: true,

	addAttributes() {
		return {
			class: {
				default: 'column column-6',
			},
		}
	},

	renderHTML({HTMLAttributes}) {
		return ['div', {class: HTMLAttributes.class, 'data-type': 'column'}, 0]
	},

	parseHTML() {
		return [
			{
				tag: 'div.column',
				getAttrs: node => ({
					span: node.getAttribute('class'),
				}),
			},
		]
	},

	addKeyboardShortcuts() {
		const moveFocus = (editor, direction) => {
			const {view} = editor
			const {state} = view
			const {$from} = state.selection

			const currentPNode = view.domAtPos($from.pos).node
			const currentColumn = currentPNode.closest('.column')

			if (!currentColumn) return false

			const grid = currentColumn.closest('.grid')
			if (!grid) return false

			const columns = Array.from(grid.querySelectorAll('.column'))
			const currentIndex = columns.indexOf(currentColumn)

			let targetColumn = null
			if (direction === 'next' && currentIndex < columns.length - 1) {
				targetColumn = columns[currentIndex + 1]
			} else if (direction === 'previous' && currentIndex > 0) {
				targetColumn = columns[currentIndex - 1]
			}

			if (targetColumn) {
				const {doc} = state

				let targetColumnPos = null
				doc.descendants((node, pos) => {
					if (node.type.name === 'column' && editor.view.nodeDOM(pos) === targetColumn) {
						targetColumnPos = pos + 1
						return false
					}
				})

				if (targetColumnPos !== null) {
					editor.commands.focus(targetColumnPos)
					return true
				}
			}

			return false
		}

		return {
			Tab: ({editor}) => {
				return moveFocus(editor, 'next')
			},
			'Shift-Tab': ({editor}) => {
				return moveFocus(editor, 'previous')
			},
		}
	},
})
