import {t, getLanguage} from 'initializers/i18n'
import {NAMESPACE} from '../constants'

export const textColorConfig = {
	types: ['heading', 'textStyle', 'listItem'],
}

export const highlightConfig = {
	multicolor: true,
}

export const imageConfig = {
	inline: false,
}

export const linkConfig = {
	protocols: ['mailto'], // additional custom protocols
	defaultProtocol: 'https',
	openOnClick: false,
	autolink: true,
}

export const taskItemConfig = {
	nested: true,
}

export const textAlignConfig = {
	types: ['heading', 'paragraph'],
}

export const youtubeConfig = {
	autoplay: false,
	interfaceLanguage: getLanguage(),
	ccLanguage: getLanguage(),
}

export const tableConfig = {
	resizable: true,
}

export const starterKitConfig = {
	heading: {
		levels: [1, 2, 3],
		HTMLAttributes: {
			class: 'no-first-letter-uppercase',
		},
	},
	paragraph: {
		HTMLAttributes: {
			class: 'no-first-letter-uppercase',
		},
	},
	dropcursor: {
		color: Colors.mediumGrey,
	},
	horizontalRule: false,
}

export const placeholderConfig = {
	placeholder: t(`${NAMESPACE}.placeholder.label`),
	includeChildren: true,
}

export const slashCommandConfig = {
	groups: [
		{
			name: 'format',
			label: t(`${NAMESPACE}.format.label`),
			commands: ['paragraph', 'heading1', 'heading2', 'heading3', 'bulletList', 'orderedList'],
		},
		{
			name: 'insert',
			label: t(`${NAMESPACE}.insert.label`),
			commands: ['image', 'youtube', 'link', 'table', 'horizontalRule', 'blockquote', 'two_columns', 'three_columns'],
		},
	],
}

export const focusConfig = {
	mode: 'all', // apply 'has-focus' class to all nodes in the tree. Other options are 'shallowest' or 'deepest'
}
