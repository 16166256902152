import {DocsLink, ModalLink} from 'components/routing'
import {EitjeDropdown, Legend, EitjeButton, Text, SHARED_SUPPORT_PROPS, startTour} from 'common/components'
import {useRoleMessage} from 'hooks'

// To align the popout with the DropdownElement
const offset = [-9, 50]

export const HelpButton = ({tourId, supportFilmId, tourRole, legendProps, collection, docsLinks = [], ...rest}) => {
	const tourDisabled = useRoleMessage({roles: tourRole})
	const docsElements = docsLinks.map(({id, t}) => {
		return {icon: 'document', element: <DocsLink to={id} t={`help_button.${t}`} />}
	})

	let elements = [
		legendProps && {
			icon: 'map',
			element: (
				<Legend {...legendProps} placement="left-start" offset={offset}>
					<Text t="legenda" />
				</Legend>
			),
		},

		tourId && {
			icon: 'flag',
			element: <Text onClick={() => startTour(tourId)} t="common.tour" />,
			disabled: tourDisabled,
		},
		supportFilmId && {
			icon: 'film',
			element: (
				<ModalLink to={`/support_film/${supportFilmId}`}>
					<Text t="common.watch_video" />
				</ModalLink>
			),
		},
		...docsElements,
		collection && {
			icon: 'book',
			element: <DocsLink to={collection} collection t="common.help_articles" />,
		},
	]

	return (
		<EitjeDropdown elements={elements} placement="bottom-end" trigger="click">
			<EitjeButton t="help" {...SHARED_SUPPORT_PROPS} {...rest} className="help-button" data-intercom-target="help-button" />
		</EitjeDropdown>
	)
}
