import {EitjeButton, Layout} from 'common/components'
import {RouteSelect} from './route_select'
import {useArticleContext} from 'cores/knowledge'
import {AttachmentButton} from './attachment_button'
import {TopicButton} from './topic_button'
import {HelpButton} from './help_button'
import {OptionsButton} from './options_button'

export const PageHeader = () => {
	const {article} = useArticleContext()
	const {published, publish, environment_ids} = article

	return (
		<Layout className="article-header" padding="24 - 8 -" margin="- auto" horizontal="spaceBetween" maxWidth={1000}>
			<RouteSelect />
			<Layout>
				{!published && (
					<EitjeButton
						t="publish"
						iconLeft="eye-open"
						colorSet="color-bordered-fill"
						onClick={publish}
						enableRole="manager"
						roleEnv={environment_ids}
					/>
				)}
				<TopicButton />
				<AttachmentButton />
				<OptionsButton />
				<HelpButton />
			</Layout>
		</Layout>
	)
}
