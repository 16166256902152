import {RouteSelect as _RouteSelect} from 'components/routing'
import {Layout, Title} from 'common/components'
import {t} from 'initializers/i18n'
import {useParams} from 'hooks'
import {Info} from 'models'

export const RouteSelect = () => {
	const {id} = useParams()
	const articles = Info.all()

	const items = articles.map(article => ({
		label: article.title,
		value: article.id,
	}))

	return (
		<Layout>
			<Title>{t('article')}:</Title>
			<_RouteSelect currentVal={id} baseUrl="/articles" items={items} shouldTranslate={false} trigger="click" maxWidth={250} />
		</Layout>
	)
}
