import {TABLE_INFO} from 'common/components/advanced_table/instances/time_registration'
import {ColumnGroupingPickers} from 'common/components/advanced_table/consumers'
import {RouteToggle} from 'cores/time_registration'
import {PageHeader as _PageHeader, HelpButton} from 'common/components'
import {PeriodPickers} from 'common/components/advanced_table/consumers'
import {Tools} from 'cores/time_registration/components/tools'
import {statusLegendItems} from 'cores/time_registration/components/status_legend'
import {usePerMemberContext} from 'cores/time_registration'
import {t} from 'initializers/i18n'

const docsLinks = [
	{id: 8228442, t: 'time_registration_basics'},
	{id: 6707882, t: 'approve_time_registration_shifts'},
	{id: 8519388, t: 'edit_time_registration_shifts'},
	{id: 6714620, t: 'how_much_did_someone_work'},
]

export const PageHeader = () => {
	const context = {pageContext: usePerMemberContext}

	return (
		<_PageHeader
			label="common.hours"
			height="small"
			left={[<PeriodPickers {...context} />, <RouteToggle />]}
			right={[
				<ColumnGroupingPickers {...TABLE_INFO} />,
				<Tools />,
				<HelpButton
					supportFilmId="time_registration_table"
					collection={3355114}
					tourId={505230}
					docsLinks={docsLinks}
					legendProps={{items: statusLegendItems(), docsId: 8228442, title: t('hours')}}
				/>,
			]}
		/>
	)
}
