import {useCallback} from 'react'
import {NodeViewWrapper} from '@tiptap/react'
import {YouTubeFormModal} from 'common/components/rich_text_editor'

export const YouTubeNodeView = ({editor, deleteNode}) => {
	const handleClose = () => editor.commands.undo()

	const handleSubmit = useCallback(
		({src, width, height}) => {
			editor.chain().setYoutubeVideo({src, width, height}).focus().run()
			deleteNode()
		},
		[editor, deleteNode],
	)

	return (
		<NodeViewWrapper>
			<YouTubeFormModal onClose={handleClose} onSubmit={handleSubmit} />
		</NodeViewWrapper>
	)
}
