import {useCallback} from 'react'
import {useEditorState} from '@tiptap/react'
import {YouTubeFormModal, useRichTextEditorContext} from 'common/components/rich_text_editor'
import {useFloatingMenu} from './hooks'
import {BarFloatingMenu} from './types'
import {FloatingMenuEditButton, FloatingMenuDeleteButton} from './components'

const COMMAND_NAME = 'youtube'

export const FloatingYouTubeMenu = () => {
	const {editor} = useRichTextEditorContext()
	const {showEdit, handleEdit, handleClose} = useFloatingMenu()
	const editorState = useEditorState({
		editor,
		selector: ({editor}) => editor.getAttributes(COMMAND_NAME),
	})

	const handleChange = useCallback(({src, width, height}) => {
		editor.commands.setYoutubeVideo({src, width, height})
		handleClose()
	}, [])

	const handleDelete = useCallback(() => {
		editor.commands.deleteSelection()
		handleClose()
	}, [])

	return (
		<>
			<BarFloatingMenu name={COMMAND_NAME} extLink={editorState.src}>
				<FloatingMenuDeleteButton onClick={handleDelete} />
				<FloatingMenuEditButton onClick={handleEdit} />
			</BarFloatingMenu>
			{showEdit && <YouTubeFormModal initialValues={editorState} onClose={handleClose} onSubmit={handleChange} />}
		</>
	)
}
