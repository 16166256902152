import {Layout, Text, EitjeButton} from 'common/components'
import {t} from 'initializers/i18n'
import {usePdfViewerContext} from '../context'

const inlineStyles = {flexShrink: 'unset'}
const buttonHeight = 'small'
const MIN_WIDTH = 128 // applied to prevent width changes when the text width of the active page changes (e.g. when going from page 9 to 10)

export const PdfPageNavigation = () => {
	const {pageNumber, pageCount, nextPage, prevPage} = usePdfViewerContext()

	if (pageCount <= 1) return null

	return (
		<Layout className="pdf-pagination" gap={0} vertical="stretch">
			<EitjeButton
				height={buttonHeight}
				iconLeft="arrow-small-left"
				onClick={prevPage}
				borderRadius="4px 0px 0px 4px"
				disabled={pageNumber === 1}
			/>
			<Layout colorSet horizontal="center" borderTop borderBottom style={inlineStyles} padding="- 16" minWidth={MIN_WIDTH}>
				<Text semiBold noTextSelect>
					{t('pdf_viewer.pagination', {page: pageNumber, pageCount})}
				</Text>
			</Layout>
			<EitjeButton
				height={buttonHeight}
				iconLeft="arrow-small-right"
				onClick={nextPage}
				borderRadius="0px 4px 4px 0px"
				disabled={pageNumber === pageCount}
			/>
		</Layout>
	)
}
