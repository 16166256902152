import {useCallback, useRef} from 'react'

export const useFileUpload = ({onUpload, accept}) => {
	const fileInput = useRef()

	const handleUploadClick = useCallback(() => {
		fileInput.current?.click()
	}, [])

	const onFileChange = async ({target = {}}) => {
		const {files} = target
		if (files) {
			return await onUpload(files)
		}
	}

	const inputElement = <input ref={fileInput} type="file" onChange={onFileChange} accept={accept} style={{display: 'none'}} />

	return {handleUploadClick, inputElement}
}
