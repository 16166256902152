import {useCallback} from 'react'
import {BubbleMenu} from '@tiptap/react'
import {useRichTextEditorContext, MENU_Z_INDEX} from 'common/components/rich_text_editor'

const popperOptions = {
	modifiers: [
		{name: 'flip', enabled: false}, // make sure popover does not flip when cut-off by parent boundary
		{
			name: 'preventOverflow',
			options: {
				altAxis: true, // make sure popover is not cut-off by parent boundaries
			},
		},
	],
}

export const BaseFloatingMenu = ({name, openFn, children}) => {
	const {editor, documentContainerRef} = useRichTextEditorContext()
	const _openFn = useCallback(() => editor.isActive(name), [])

	return (
		<BubbleMenu
			editor={editor}
			pluginKey={name}
			shouldShow={openFn || _openFn}
			updateDelay={0}
			tippyOptions={{
				zIndex: MENU_Z_INDEX,
				maxWidth: 400,
				popperOptions,
				appendTo: () => documentContainerRef?.current,
			}}
		>
			{children}
		</BubbleMenu>
	)
}
