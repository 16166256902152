import {t} from 'initializers/i18n'
import {FormRow} from 'components/ui'
import useQueryParams from 'hooks/use_query_params'
import {useSimpleContext} from 'contexts/simple'
import {ListPicker} from 'common/components'

const ColumnPicker = ({formFields}) => {
	const {ids} = useQueryParams()
	const opts = formFields().uniq(field => field.field)
	const {type} = useSimpleContext()
	const _opts = opts.map(o => ({id: o.field, disabled: o.disabled, name: t(`form.shift.fields.${o.field}.label`, {type})}))

	return (
		<FormRow>
			<ListPicker
				form
				easyRemove
				dropdown
				placeholder={t('records.default.fields.columns')}
				i18nOpts={{count: ids.length, type}}
				field="columns"
				items={_opts}
			/>
		</FormRow>
	)
}

export default ColumnPicker
