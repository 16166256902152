import PerMemberProvider from './per_member_provider'
import {OpenShiftsRow} from 'cores/planning/components/open_shifts_row'
import {PlanningPerMemberTable} from './planning_per_member_table'
import {EventRow} from 'cores/planning'
import React, {useEffect, useRef, useState} from 'react'
import {useResizeObserver} from 'hooks'
import usePlanningTeams from 'cores/planning/hooks/use_planning_teams'
import EmptyTeamRow from '../env_table/empty_team_row'
import {makeCnVariants} from 'helpers'
import './styles/index.less'
import {useSearchContext} from 'contexts/search'
import {useFilterFooterContext} from 'common/components'
import {Team} from 'models'

const Inner = () => {
	const container = useRef()
	const userTableRef = useRef()
	const {search} = useSearchContext()
	const {envs, hasFiltered} = useFilterFooterContext()
	const [hideTable, setHideTable] = useState()
	const tableInView = useRef()
	const hasTeams = !!usePlanningTeams()?.length
	const {height} = useResizeObserver(container)

	useEffect(() => {
		// Temporarily hide the virtuoso table to ensure it is triggered to render elements
		// The problem is that it was mounted out of view and came into the view without scrolling
		if (window.innerHeight > userTableRef.current?.offsetTop && !tableInView.current) {
			setHideTable(true)
			tableInView.current = true
		}
	}, [height])

	useEffect(() => {
		if (hideTable) setHideTable(false)
	}, [hideTable])

	return (
		<div ref={container} className="planning-per-member">
			{hasTeams && <EventRow envIds={envs.ids()} />}
			{!search && hasTeams && <OpenShiftsRow />}
			{!search && !hasTeams && <EmptyTeamRow />}
			{
				<div className="planning-per-member-table" ref={userTableRef}>
					{!hideTable && <PlanningPerMemberTable />}
				</div>
			}
		</div>
	)
}

export const PerMember = () => {
	return (
		<PerMemberProvider>
			<Inner />
		</PerMemberProvider>
	)
}

export default React.memo(PerMember, areEqual)

function areEqual() {
	return true
}
