import {createContext, useContext, useEffect, useState, useCallback, useRef} from 'react'

const PdfViewerContext = createContext({})
const {Provider} = PdfViewerContext

export const PdfViewerProvider = ({pdf, dropdownItems, children}) => {
	const documentContainerRef = useRef()
	const pageRefs = useRef([])
	const [pageCount, setPageCount] = useState(null)
	const [pageNumber, setPageNumber] = useState()

	useDocumentScroll({documentContainerRef, pageNumber, setPageNumber})

	const onLoadSuccess = useCallback(({numPages}) => {
		setPageCount(numPages)
		if (!pageNumber) setPageNumber(1)
	}, [])

	const scrollToPage = pageIndex => {
		const page = pageRefs.current[pageIndex]
		if (page) page.scrollIntoView({behavior: 'smooth', block: 'start'})
	}

	const nextPage = useCallback(() => {
		if (pageNumber === pageCount) return

		scrollToPage(pageNumber)
	}, [pageNumber, pageCount])

	const prevPage = useCallback(() => {
		if (pageNumber === 1) return

		scrollToPage(pageNumber - 2)
	}, [pageNumber])

	// Update page number when internal links are clicked, e.g.  a thumbnail or table of contents
	const onItemClick = useCallback(({pageNumber: itemPageNumber}) => {
		scrollToPage(itemPageNumber - 1)
	}, [])

	const state = {
		pdf,
		dropdownItems,
		pageCount,
		pageNumber,
		nextPage,
		prevPage,
		onLoadSuccess,
		onItemClick,
		pageRefs,
		documentContainerRef,
	}

	return <Provider value={state} children={children} />
}

export const usePdfViewerContext = () => useContext(PdfViewerContext)

function useDocumentScroll({documentContainerRef, pageNumber, setPageNumber}) {
	const container = documentContainerRef.current

	const handleScroll = useCallback(() => {
		if (!container) return

		const containerTop = container.getBoundingClientRect().top
		const pages = container.querySelectorAll('.react-pdf__Page')

		let closestPage = 1
		let smallestOffset = Infinity

		pages.forEach((page, index) => {
			const rect = page.getBoundingClientRect()
			const offset = Math.abs(rect.top - containerTop)

			if (offset < smallestOffset) {
				smallestOffset = offset
				closestPage = index + 1
			}
		})

		if (closestPage !== pageNumber) setPageNumber(closestPage)
	}, [pageNumber])

	useEffect(() => {
		if (container) container.addEventListener('scroll', handleScroll)
		return () => {
			if (container) container.removeEventListener('scroll', handleScroll)
		}
	}, [handleScroll])
}
