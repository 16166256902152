// Helper function to handle column actions
export const performColumnAction = count => editor => {
	return editor
		.chain()
		.focus()
		.setColumns(count)
		.command(({state, commands}) => {
			const {$from} = state.selection
			let depth = $from.depth
			let gridPos = null

			// find parent grid node
			while (depth > 0) {
				if ($from.node(depth).type.name === 'columns') {
					gridPos = $from.before(depth)
					break
				}
				depth--
			}

			if (gridPos !== null) {
				const firstColumnPos = gridPos + 1
				commands.focus(firstColumnPos + 1) // focus first child of first column
			}
		})
		.run()
}
