import {useCallback, useState} from 'react'
import {useRichTextEditorContext} from 'common/components/rich_text_editor'

export const useCurrentNodeActions = () => {
	const [currentNode, setCurrentNode] = useState(null)
	const [currentNodePos, setCurrentNodePos] = useState(-1)
	const {editor} = useRichTextEditorContext()

	const handleNodeChange = useCallback(({node, pos}) => {
		if (node) setCurrentNode(node)
		setCurrentNodePos(pos)
	}, [])

	const duplicateNode = useCallback(() => {
		const lastNodePos = getLastDocNodePos(editor.state)
		if (currentNodePos > lastNodePos) return

		editor.commands.setNodeSelection(currentNodePos)

		const {$anchor} = editor.state.selection
		const selectedNode = $anchor.node(1) || editor.state.selection.node

		editor
			.chain()
			.insertContentAt(currentNodePos + (currentNode?.nodeSize || 0), selectedNode.toJSON())
			.run()
	}, [currentNodePos, currentNode?.nodeSize])

	const deleteNode = useCallback(() => {
		const lastNodePos = getLastDocNodePos(editor.state)

		if (currentNodePos > lastNodePos) return

		editor.chain().setNodeSelection(currentNodePos).deleteSelection().run()
	}, [currentNodePos])

	const addNode = useCallback(() => {
		if (currentNodePos !== -1) {
			const {nodeSize = 0, type, content} = currentNode
			const insertPos = currentNodePos + nodeSize
			const currentNodeIsEmptyParagraph = type?.name === 'paragraph' && content?.size === 0
			const focusPos = currentNodeIsEmptyParagraph ? currentNodePos + 2 : insertPos + 2

			editor
				.chain()
				.command(({dispatch, tr, state}) => {
					const {
						schema,
						doc: {content},
					} = state
					const lastNodePos = getLastDocNodePos(state)

					if (dispatch) {
						if (currentNodeIsEmptyParagraph) {
							const pos = Math.min(lastNodePos, currentNodePos)

							if (currentNodePos > lastNodePos) {
								tr.insert(content.size, schema.nodes.paragraph.create(null, [schema.text('/')]))
							} else {
								tr.insertText('/', pos, pos + 1)
							}
						} else {
							const pos = Math.min(lastNodePos, insertPos)
							tr.insert(pos, schema.nodes.paragraph.create(null, [schema.text('/')]))
						}

						return dispatch(tr)
					}

					return true
				})
				.focus(focusPos)
				.run()
		}
	}, [currentNode, currentNodePos])

	return {
		currentNodePos,
		duplicateNode,
		deleteNode,
		addNode,
		handleNodeChange,
	}
}

export function getLastDocNodePos(state) {
	const {lastChild, content} = state.doc
	return content.size - lastChild.nodeSize
}
