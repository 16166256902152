import {WeekTableDaysHeader as BaseHeader} from 'common/components/week_table'
import {useItems, useDayCheckbox} from './day_content'
import {usePanelItems, usePanelCheckbox} from './panel_content'
import {DayDropdown, PanelDropdown} from './components/dropdown'
import {usePerWeekContext, MultiActionCheckbox} from 'cores/time_registration'
import {makeRange} from 'helpers/date'
import {usePageContext} from 'common/components'
import {checkboxAsLeftContent} from 'common/components/week_table'

export const NAMESPACE = 'time_registration.week_table_days_header'

export const WeekTableDaysHeader = () => {
	const {dateArray} = usePerWeekContext()
	return (
		<BaseHeader
			dateArray={dateArray}
			dayContent={useItems}
			dayContentLeft={useDayCheckbox}
			DayDropdown={DayDropdown}
			panelContent={usePanelItems}
			panelContentLeft={usePanelCheckbox}
			PanelDropdown={PanelDropdown}
		/>
	)
}

export function useCheckboxLeft(shifts) {
	const {multiEdit} = usePageContext()
	return checkboxAsLeftContent({shifts, checkbox: multiEdit && MultiActionCheckbox})
}
