import React, {useEffect, useState, useRef} from 'react'
import {DatePicker} from 'antd'
import {rangeToDateArray, rangeFromStart, rangeForMonth} from 'helpers/date'
import useOutsideClick from 'hooks/use_outside_click'
import {EitjeButton, Text, Layout} from 'common/components'

const classNames = ['ant-picker-dropdown', 'ant-picker-cell-inner']

const FixedRangePicker = ({
	length = 7,
	initialValue,
	initialLeading,
	isMonthPicker,
	isWeekPicker = !isMonthPicker && length % 7 == 0,
	onChange,
	height,
	...props
}) => {
	const [visible, setVisible] = useState(false)
	const wrapper = useRef(null)
	useOutsideClick(wrapper, () => setVisible(false), {classNames, checkParents: true})
	let [date, setDate] = useState(initialValue)

	if (initialLeading) {
		date = initialValue
	}

	date = date.clone()

	if (isWeekPicker) date = date.startOf('week')
	if (isMonthPicker) date = date.startOf('month')

	const dateRange = isMonthPicker ? rangeForMonth({date: date}) : rangeFromStart({start: date, length})
	const {start, end} = dateRange

	const isSmall = height === 'small'

	useEffect(() => {
		if (!initialLeading) {
			onChange(rangeToDateArray(dateRange))
			setVisible(false)
		}
	}, [date.format('YY-MM-DDDD')]) // we don't trust moments internals to implement isEq properly

	const type = isMonthPicker ? 'month' : isWeekPicker ? 'weeks' : 'day'

	const _setDate = date => {
		date = date.startOf(type)
		const _dateRange = isMonthPicker ? rangeForMonth({date: date}) : rangeFromStart({start: date, length})
		if (initialLeading) {
			return onChange(rangeToDateArray(_dateRange))
		}
		setDate(date)
	}

	const amtPeriods = isMonthPicker ? 1 : Math.round(length / (isWeekPicker ? 7 : 1)) || 1

	const incrementDate = () => _setDate(date.add(amtPeriods, type))
	const decrementDate = () => _setDate(date.subtract(amtPeriods, type))

	const startDate = start.format('DD MMM')
	const endDate = end.format('DD MMM')
	const dateTitle = startDate == endDate ? start.format('DD MMM YYYY') : `${startDate} - ${endDate}`

	const buttonProps = {height, padding: '0 8'}

	return (
		<Layout>
			<Layout ref={wrapper} className="eitje-fixed-range-picker" gap={0}>
				<EitjeButton iconLeft="arrow-small-left" onClick={decrementDate} {...buttonProps} />
				<div>
					<Layout
						className="date-container"
						colorSet
						onClick={() => setVisible(!visible)}
						horizontal="center"
						height={isSmall ? __SMALL_BUTTON_HEIGHT : __LARGE_BUTTON_HEIGHT}
						padding={4}
						border
					>
						<Text semiBold>{dateTitle}</Text>
					</Layout>
					{visible && <DatePicker bordered={false} open value={date} onChange={_setDate} {...props} />}
				</div>
				<EitjeButton iconLeft="arrow-small-right" onClick={incrementDate} {...buttonProps} />
			</Layout>
		</Layout>
	)
}

export default FixedRangePicker
