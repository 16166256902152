import {EitjeButton, EitjeDropdown, Text} from 'common/components'
import {usePdfViewerContext} from '../context'

export const PdfActionsDropdown = () => {
	const {pdf, dropdownItems} = usePdfViewerContext()

	const elements = [
		{
			icon: 'download',
			element: <Text t="download" />,
			onClick: () => window.open(pdf, '_blank'),
		},
		...dropdownItems,
	]

	return (
		<EitjeDropdown elements={elements} placement="bottom-start" maxWidth={300} trigger="click" visibleAfterClick>
			<EitjeButton t="pdf_viewer.pdf_options" dropdown />
		</EitjeDropdown>
	)
}
