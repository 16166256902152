import {EitjeButton, EitjeDropdown, Text, DeletePopout} from 'common/components'
import {NAMESPACE, useArticleContext} from 'cores/knowledge'
import {useFileUpload} from 'hooks'

export const AttachmentButton = () => {
	const {article, editDisabled} = useArticleContext()
	const {attachments, addAttachment, removeAttachment} = article
	const {handleUploadClick, inputElement} = useFileUpload({onUpload: addAttachment})

	const attachmentCount = attachments.length
	const uploadElement = {
		icon: 'upload',
		disabled: editDisabled,
		element: (
			<>
				<Text t={`${NAMESPACE}.${attachmentCount ? 'add_file' : 'attachment'}`} />
				{inputElement}
			</>
		),
		onClick: handleUploadClick,
	}

	if (!attachmentCount)
		return (
			<EitjeButton iconLeft="plus" onClick={uploadElement.onClick} disabled={editDisabled}>
				{uploadElement.element}
			</EitjeButton>
		)

	const elements = [
		uploadElement,
		...attachments.map(({name, url}, index) => ({
			element: <Text truncate>{name}</Text>,
			children: [
				{
					icon: 'download',
					element: <Text t="download" />,
					onClick: () => window.open(url, '_blank'),
				},
				{
					Popout: DeletePopout,
					popoutProps: {
						onDelete: () => removeAttachment(index),
						placement: 'right-start',
						trigger: 'hover',
						disabled: !!editDisabled, // needed to actually disable to trigger
					},
					trigger: {
						icon: 'trash',
						element: <Text t="delete" />,
						disabled: editDisabled, // needed for anchoring the disabled popout at the correct position
					},
				},
			],
		})),
	]

	return (
		<EitjeDropdown elements={elements} placement="bottom-start" maxWidth={300} trigger="click" visibleAfterClick>
			<EitjeButton iconLeft="paperclip">
				<Text countSuffix={attachmentCount} t="attachment" />
			</EitjeButton>
		</EitjeDropdown>
	)
}
