import {findParentNode} from '@tiptap/core'
import {CellSelection, TableMap} from '@tiptap/pm/tables'

// copied file from: https://github.com/ueberdosis/tiptap-templates/tree/main/templates/next-block-editor-app

const isRectSelected = rect => selection => {
	const map = TableMap.get(selection.$anchorCell.node(-1))
	const start = selection.$anchorCell.start(-1)
	const cells = map.cellsInRect(rect)
	const selectedCells = map.cellsInRect(map.rectBetween(selection.$anchorCell.pos - start, selection.$headCell.pos - start))

	for (let i = 0, count = cells.length; i < count; i += 1) {
		if (selectedCells.indexOf(cells[i]) === -1) {
			return false
		}
	}

	return true
}

const findTable = selection => findParentNode(node => node.type.spec.tableRole && node.type.spec.tableRole === 'table')(selection)

const isCellSelection = selection => selection instanceof CellSelection

export const isColumnSelected = columnIndex => selection => {
	if (isCellSelection(selection)) {
		const map = TableMap.get(selection.$anchorCell.node(-1))

		return isRectSelected({
			left: columnIndex,
			right: columnIndex + 1,
			top: 0,
			bottom: map.height,
		})(selection)
	}

	return false
}

export const isRowSelected = rowIndex => selection => {
	if (isCellSelection(selection)) {
		const map = TableMap.get(selection.$anchorCell.node(-1))

		return isRectSelected({
			left: 0,
			right: map.width,
			top: rowIndex,
			bottom: rowIndex + 1,
		})(selection)
	}

	return false
}

export const getCellsInColumn = columnIndex => selection => {
	const table = findTable(selection)

	if (table) {
		const map = TableMap.get(table.node)
		// if -1 is passed, pick last column
		const idx = columnIndex === -1 ? map.width - 1 : columnIndex
		const indexes = Array.isArray(idx) ? idx : Array.from([idx])

		return indexes.reduce((acc, index) => {
			if (index >= 0 && index <= map.width - 1) {
				const cells = map.cellsInRect({
					left: index,
					right: index + 1,
					top: 0,
					bottom: map.height,
				})

				return acc.concat(
					cells.map(nodePos => {
						const node = table.node.nodeAt(nodePos)
						const pos = nodePos + table.start

						return {pos, start: pos + 1, node}
					}),
				)
			}

			return acc
		}, [])
	}
	return null
}

export const getCellsInRow = rowIndex => selection => {
	const table = findTable(selection)

	if (table) {
		const map = TableMap.get(table.node)
		const indexes = Array.isArray(rowIndex) ? rowIndex : Array.from([rowIndex])

		return indexes.reduce((acc, index) => {
			if (index >= 0 && index <= map.height - 1) {
				const cells = map.cellsInRect({
					left: 0,
					right: map.width,
					top: index,
					bottom: index + 1,
				})

				return acc.concat(
					cells.map(nodePos => {
						const node = table.node.nodeAt(nodePos)
						const pos = nodePos + table.start
						return {pos, start: pos + 1, node}
					}),
				)
			}

			return acc
		}, [])
	}

	return null
}

export const getCellsInTable = selection => {
	const table = findTable(selection)

	if (table) {
		const map = TableMap.get(table.node)
		const cells = map.cellsInRect({
			left: 0,
			right: map.width,
			top: 0,
			bottom: map.height,
		})

		return cells.map(nodePos => {
			const node = table.node.nodeAt(nodePos)
			const pos = nodePos + table.start

			return {pos, start: pos + 1, node}
		})
	}

	return null
}

const select = type => index => tr => {
	const table = findTable(tr.selection)
	const isRowSelection = type === 'row'

	if (table) {
		const map = TableMap.get(table.node)

		// Check if the index is valid
		if (index >= 0 && index < (isRowSelection ? map.height : map.width)) {
			const left = isRowSelection ? 0 : index
			const top = isRowSelection ? index : 0
			const right = isRowSelection ? map.width : index + 1
			const bottom = isRowSelection ? index + 1 : map.height

			const cellsInFirstRow = map.cellsInRect({
				left,
				top,
				right: isRowSelection ? right : left + 1,
				bottom: isRowSelection ? top + 1 : bottom,
			})

			const cellsInLastRow =
				bottom - top === 1
					? cellsInFirstRow
					: map.cellsInRect({left: isRowSelection ? left : right - 1, top: isRowSelection ? bottom - 1 : top, right, bottom})

			const head = table.start + cellsInFirstRow[0]
			const anchor = table.start + cellsInLastRow[cellsInLastRow.length - 1]
			const $head = tr.doc.resolve(head)
			const $anchor = tr.doc.resolve(anchor)

			return tr.setSelection(new CellSelection($anchor, $head))
		}
	}
	return tr
}

export const selectColumn = select('column')

export const selectRow = select('row')

export const selectTable = tr => {
	const table = findTable(tr.selection)

	if (table) {
		const {map} = TableMap.get(table.node)

		if (map && map.length) {
			const head = table.start + map[0]
			const anchor = table.start + map[map.length - 1]
			const $head = tr.doc.resolve(head)
			const $anchor = tr.doc.resolve(anchor)

			return tr.setSelection(new CellSelection($anchor, $head))
		}
	}

	return tr
}
