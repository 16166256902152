import {hooklessRoleMessage, useRoleOrgEnvs, useShared} from 'hooks'
import {Topic} from 'models'

export const useTopics = () => {
	const {orgEnvs} = useShared()
	const roleEnvs = useRoleOrgEnvs('manager')
	const baseTopics = Topic.all()

	const topics = baseTopics.map(topic => {
		const disabled = hooklessRoleMessage({roles: 'manager', envId: topic.environment_ids, roleEnvs, orgEnvs})
		return {
			...topic,
			disabled,
		}
	})

	return topics
}
