import {AttachmentPreviewList as StreamAttachmentPreviewList} from 'stream-chat-react'
import {Icon} from '@eitje/web_components'
import {ButtonBar} from 'common/components'
import {normalizeAttachment} from 'cores/chat'

const attachmentIcons = {
	audio: 'musical-note',
	file: 'paperclip',
	image: 'picture',
	video: 'film',
}

const Attachment = ({attachment, removeAttachments}) => {
	const {type, title, metadata} = normalizeAttachment(attachment)
	const {id, uploadState} = metadata

	const isUploading = uploadState === 'uploading'
	const isFailed = uploadState === 'failed'

	const removeAttachment = () => removeAttachments(id)

	return (
		<ButtonBar
			colorSet={isFailed && 'color-bordered-color-content'}
			baseColor={isFailed && 'red'}
			iconLeft={attachmentIcons[type]}
			label={title}
			loading={isUploading}
			iconRight={<Icon name="cross" onClick={removeAttachment} />}
		/>
	)
}

export const AttachmentPreviewList = props => {
	return (
		<StreamAttachmentPreviewList
			{...props}
			FileAttachmentPreview={Attachment}
			ImageAttachmentPreview={Attachment}
			AudioAttachmentPreview={Attachment}
			VideoAttachmentPreview={Attachment}
		/>
	)
}
