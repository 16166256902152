import utils from '@eitje/web_utils'
import {useFilteredShifts, useEditableFilteredShifts, useNonPlanShifts} from 'cores/time_registration'
import {NAMESPACE, useCheckboxLeft} from '.'
import {Association, PlanningShift} from 'models'

const mins = shifts => {
	const mins = shifts.sum(s => s.totalMins())
	return utils.minutesToString(mins)
}

export function useItems(dateOrRange) {
	const regShifts = useNonPlanShifts().where({date: dateOrRange})
	const workedShifts = regShifts.filter(s => s.typ === 'gewerkte_uren')
	const inProgressShifts = regShifts.filter(s => s.regStatus() === 'in_progress')
	const improductiveShifts = regShifts.filter(s => s.improductiveType?.()) // check ins don't have improductiveType as fn

	// To show the amount of planned hours, use both the planning shift records directly (for which there are no
	// registered shifts yet), and mix them with planning shifts which belong to approved registered shifts.
	const {planShifts: unapprovedPlanShifts} = useFilteredShifts()
	const approvedPlanShiftIds = regShifts._map('planning_shift_id').filter(Boolean).uniq()
	const approvedPlanShifts = PlanningShift.where(approvedPlanShiftIds)
	const planShifts = new Association([...approvedPlanShifts, ...unapprovedPlanShifts]).where({date: dateOrRange})

	const shared = {type: 'greySubtitle', namespace: NAMESPACE}
	return [
		{value: mins(regShifts), localValue: 'hoursShowTotalRegistered', ...shared},
		{value: mins(workedShifts), localValue: 'hoursShowTotalWorked', ...shared},
		{value: mins(inProgressShifts), localValue: 'hoursShowTotalInProgress', ...shared},
		{value: mins(improductiveShifts), localValue: 'hoursShowTotalImproductive', ...shared},
		{value: mins(planShifts), localValue: 'hoursShowPlannedWorkedDiff', ...shared},
	]
}

export function useDayCheckbox(date) {
	const shifts = useEditableFilteredShifts().where({date})
	return useCheckboxLeft(shifts)
}
