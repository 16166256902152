import utils from '@eitje/utils'
import useSelector from 'hooks/use_selector'
import {anyEnvRoleSelector, roleOrgEnvsSelector, roleSelector} from 'selectors/new_settings'

export const useHasRole = (roleName, envId) => {
	const envIds = envId && utils.alwaysDefinedArray(envId)
	// selectors only selects all envs if passed value is falsy

	return useSelector(anyEnvRoleSelector, roleName, envIds)
}

export const useHasRoleInCurrentEnv = roleName => {
	return useSelector(roleSelector, roleName)
}

export const useRoleEnvs = roleName => {
	return useSelector(roleOrgEnvsSelector, roleName)
}

export default useHasRole
