import {backend, find} from '@eitje/easy_api'
import {Spin} from 'antd'
import {SyncAgendaBtn} from 'components/general'
import {ModalLink} from 'components/routing'
import {MaxList} from 'components/ui'
import {t} from 'initializers/i18n'
import _ from 'lodash'
import {date} from 'initializers/date'
import React from 'react'
import {connect, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {getEnvSetting, roleSelector} from 'selectors/new_settings'
import {daysAfterToday, inEnv} from 'selectors/records'
import * as jsUtils from 'utils/jsutils'
import utils from '@eitje/utils'
import 'pages/v3/news/styles/news.less'
import TradeTrigger from 'cores/planning/components/trade/approve_trade/trigger'
import {API} from '@eitje/easy_api'
import {envPendingVerzoekenSel} from 'selectors/verlof'
import {Tabs} from 'common/components'
import {EitjeAvatar} from 'common/components'

const UserCard = ({item}) => (
	<div className="news-dashboard-list-item">
		<EitjeAvatar user={item} />
		<div className="news-dashboard-list-text-container">
			<p className="bold">{jsUtils.truncateString(item.full_name, 16)}</p>
			<p className="eitje-text-secondary">{t('extraCute')}</p>
		</div>
	</div>
)

const BirthdayToday = ({item}) => t('bdayToday')
const BirthdayFuture = ({item}) => `${t('bdayUpcoming')} ${jsUtils.noYearDate(item.verjaardag)}`

const Birthday = ({item}) => {
	const hasBirthdayToday = date().format('DD-MM') == date(item.verjaardag).format('DD-MM')
	return (
		<div className="news-dashboard-list-item">
			<EitjeAvatar icon="birthday-cake" />

			<div>
				<p className="panelBoldText">{jsUtils.truncateString(item.full_name, 16)}</p>
				<p className="panelUnderlineText">{hasBirthdayToday ? <BirthdayToday item={item} /> : <BirthdayFuture item={item} />}</p>
			</div>
		</div>
	)
}

const Event = ({item, onC}) => (
	<ModalLink to={`/forms/events/${item.id}`} className="news-dashboard-list-item hover-effect">
		<EitjeAvatar icon="calendar" />

		<div>
			<p className="bold">{jsUtils.truncateString(item.title, 16)}</p>
			<p className="eitje-text-secondary">
				{jsUtils.shortDate(item.date)} - {item.from}
			</p>
		</div>
	</ModalLink>
)

const Verlof = ({item, onClick}) => (
	<div onClick={onClick} className="news-dashboard-list-item">
		<EitjeAvatar user={item.user} />

		<div className="news-dashboard-list-text-container">
			<p className="bold">{jsUtils.truncateString(item?.user?.full_name, 16)}</p>
			<p className="eitje-text-secondary">
				{jsUtils.shortDate(item.start_datetime)} - {jsUtils.shortDate(item.end_datetime)}
			</p>
		</div>
	</div>
)

export const Ruilen = ({item, afterSucc, hasDate, hasTime = false, hasBorder = false}) => {
	return (
		<TradeTrigger hideAmtWorked id={item.id} afterSucc={afterSucc}>
			<div
				className={'dbClick'}
				style={{
					cursor: 'pointer',
					backgroundColor: hasBorder && '#ecf6fd',
					boxShadow: hasBorder && '0 0px 0px rgba(0,0,0,0)',
				}}
			>
				<TradeBlock hasDate={hasDate} hasTime={hasTime} item={item.shift || item.pretty_shift} user={item.user} />

				{item.other_shift && (
					<div>
						<div className="tradeTogether fRow aCen jCen">
							<img src={`/images/web/icons/tradeTogether.png`} />
						</div>
						<TradeBlock hasDate={hasDate} hasTime={hasTime} item={item.other_shift} user={item.other_user} />
					</div>
				)}

				<div className="clear"></div>
			</div>
		</TradeTrigger>
	)
}

const TradeBlock = ({hasTime, hasDate, item = {}, user}) => {
	const avatarProps = user?.id ? {user} : {type: 'open-shift'}
	return (
		<div className="fRow aCen jBetween">
			<div className="fRow aCen jSta">
				<div className="dbAva">
					<EitjeAvatar {...avatarProps} />
				</div>

				<div className="">
					<p className="panelBoldText">{jsUtils.truncateString(user?.full_name || 'Open shift', 16)}</p>
					{!hasTime && <p className="panelUnderlineText">{jsUtils.shortDate(item.date)}</p>}

					{hasTime && (
						<div className="fRow">
							{hasDate && <p className="panelUnderlineText">{jsUtils.shortDate(item.date)} |&nbsp; </p>}
							<p className="panelUnderlineText">{item.from} -&nbsp; </p>
							<p className="panelUnderlineText">{item.till} </p>
							{item.team && <p className="panelUnderlineText">&nbsp;- {item.team?.naam || item.team_naam}</p>}
						</div>
					)}
				</div>
			</div>
		</div>
	)
}

const Contract = ({item}) => {
	const user = useSelector(state => find(state, 'users', item.user_id))
	const contractError = date().startOf('day').diff(item.end_date, 'M') == 0
	const spanClassName = utils.makeCns('eitje-text-secondary', `text-color-${contractError ? 'error' : 'warn'}`)
	return (
		<Link to={`/user/${item.user_id}/contracts`}>
			<div className="news-dashboard-list-item">
				<EitjeAvatar user={user} />

				<div className="news-dashboard-list-text-container">
					<p className="bold"> {jsUtils.truncateString(user.full_name, 16)} </p>
					<p className="eitje-text-secondary">
						{t('endsOn')}:<span className={spanClassName}> {jsUtils.shortDate(item.end_date)}</span>
					</p>
				</div>
			</div>
		</Link>
	)
}

const bdayMap = users => {
	const currentMmt = date().startOf('day') // bday of today is ok
	const currentYear = currentMmt.year()
	return users.map(u => {
		const mmt = date(u.verjaardag).set('year', currentYear)
		const year = mmt < currentMmt ? currentYear + 1 : currentYear
		return {...u, date: date(u.verjaardag).set('year', year).format('YYYY-MM-DD')}
	})
}

class NewsDashboard extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			to_accord: [],
			near_birthdays: [],
			near_events: [],
			managerActive: props.isC && props.nearContracts.length > 0,
		}
	}

	componentDidMount() {
		const {envId} = this.props
		if (envId) {
			this.fetch()
		}
	}

	async fetch() {
		const {nearContracts} = this.props
		const res = await backend.get(`stats/news_dashboard`)
		if (res.ok) {
			const d = {...res.data, near_birthdays: bdayMap(res.data.near_birthdays)}
			const {to_accord} = d
			if (utils.exists(to_accord)) {
				API.getByIds(
					'trade_requests',
					to_accord.map(d2 => d2.id),
				)
			}
			await this.setState({...d, hasLoaded: true})
			this.setState({managerActive: this.hasAny()})
		}
	}

	nearContracts() {
		const {nearContracts, envId, users} = this.props
		const userIds = users.map(u => u.id)
		const filtered = nearContracts.filter(c => c.environment_id == envId && userIds.includes(c.user_id))
		return _.orderBy(filtered, 'end_date')
	}

	hasAny() {
		const {isC, isM, leaveRequests, isV} = this.props
		const {to_accord} = this.state
		return (isV && leaveRequests.length > 0) || (isM && to_accord.length > 0) || (isC && this.nearContracts().length > 0)
	}

	render() {
		let {user, isM, isV, isC, leaveRequests, hasUren, nearEvents} = this.props
		const {near_birthdays, newest_user, to_accord, managerActive, hasLoaded} = this.state
		const nearContracts = this.nearContracts()
		const nearItems = _.orderBy([...near_birthdays, ...nearEvents], 'date')

		return (
			<div className="newspage-information-column right-container">
				{(isM || isC || isV) && (
					<div className="switch-management-personal-info">
						{this.hasAny() && <div className="managerActiveDot" />}
						<Tabs
							raw
							width="full"
							value={managerActive}
							onChange={value => this.setState({managerActive: value})}
							items={[
								{name: t('management'), id: true},
								{name: t('personal'), id: false},
							]}
						/>
					</div>
				)}

				<div>
					{!hasLoaded ? (
						<Spin style={{position: 'absolute', left: '50%', top: 120}} spinning={true} />
					) : // ) : false ? (
					managerActive ? (
						<div className="vertical-padding-xs" id="mgmt">
							{isC && nearContracts.length >= 1 && (
								<div className="padding-top-lg">
									<div className="column align-items-center margin-bottom-sm">
										<img width={48} height={48} className="margin-bottom-sm" src={`/images/web/icons/contract.png`} />
										<p className="bold">
											{t('contractsEnding')} ({nearContracts.length})
										</p>
									</div>
									<MaxList initialMax={4}>
										{nearContracts.map(cont => (
											<Contract item={cont} />
										))}
									</MaxList>
								</div>
							)}

							{hasUren && isV && leaveRequests.length >= 1 && (
								<div className="news-very-large-padding-top">
									<div className="column-with-justify-center align-items-center margin-bottom-sm">
										<img width={48} height={48} className="margin-bottom-sm" src={`/images/web/icons/leave.png`} />
										<p className="bold">
											{t('leaveRequests')} ({leaveRequests.length})
										</p>
									</div>
									<MaxList initialMax={4}>
										{leaveRequests.map(verzoek => (
											<ModalLink to={`/leave/${verzoek.id}/approve`}>
												<Verlof item={verzoek} />
											</ModalLink>
										))}
									</MaxList>
								</div>
							)}

							{isM && to_accord.length >= 1 && (
								<div className="news-very-large-padding-top">
									<div className="column-with-justify-center align-items-center margin-bottom-sm">
										<img width={48} height={48} className="panelImg" src={`/images/web/icons/tradeshifts.png`} />
										<p className="pBold">
											{t('approveTrade')} ({to_accord.length})
										</p>
									</div>
									<MaxList initialMax={4}>
										{to_accord.map(rv => (
											<Ruilen item={rv} afterSucc={() => this.fetch()} />
										))}
									</MaxList>
								</div>
							)}

							{!this.hasAny() && (
								<div className="row-with-justify-center padding-top-sm">
									<h5 className="bold">{t('noTasks')}</h5>
								</div>
							)}
						</div>
					) : (
						<div>
							<div className="news-dashboard-details-container">
								<EitjeAvatar radius={64} user={user} />

								<p className="bold padding-top-sm">{user.full_name}</p>
								<p className="eitje-text-secondary padding-bottom-sm">
									{t('vloerCode')}: {user.vloer_code}
								</p>

								<SyncAgendaBtn agendaStyle={{width: '100%'}} />
							</div>

							{false && ( // Does this ever get rendered?
								<div className="dbClick fRow aCen jSta">
									<img className="panelImgXS" style={{marginRight: 12}} src={`/images/web/icons/tradeshifts.png`} />
									<p className="panelBoldText smallBoldText pointer">{t('tradeYourShift')}</p>
								</div>
							)}

							{nearItems.length > 0 && (
								<div className="news-dashboard-list-container">
									<p className="news-dashboard-list-header">
										{t('nextUp')} ({nearItems.length})
									</p>
									<MaxList initialMax={3}>
										{nearItems.map(item => (item.email ? <Birthday key={item.id} item={item} /> : <Event item={item} />))}
									</MaxList>
								</div>
							)}

							{newest_user && (
								<div className="news-dashboard-list-container">
									<p className="news-dashboard-list-header">{t('newMember')}</p>

									<UserCard newest_user={newest_user} item={newest_user} />
								</div>
							)}
						</div>
					)}
				</div>
			</div>
		)
	}
}

export default connect(state => ({
	leaveRequests: envPendingVerzoekenSel(state),
	user: state.auth.user,
	settings: state.settings.envSettings,
	isC: roleSelector(state, 'contracten'),
	isM: roleSelector(state, 'manager'),
	isV: roleSelector(state, 'verlof'),
	envId: state.environment.active,
	users: inEnv(state, 'users'),
	teams: state.entities.teams,
	hasUren: getEnvSetting(state, 'uren_reg', 'actief'),
	nearEvents: daysAfterToday(state, 'events', 31),
	nearContracts: daysAfterToday(state, 'contractHolders', 60, {field: 'end_date'}),
}))(NewsDashboard)
