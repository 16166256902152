import {Underline} from '@tiptap/extension-underline'
import {Table} from '@tiptap/extension-table'
import {TextStyle} from '@tiptap/extension-text-style'
// import {TaskItem} from '@tiptap/extension-task-item'
// import {TaskList} from '@tiptap/extension-task-list'
import {TextAlign} from '@tiptap/extension-text-align'
import {StarterKit} from '@tiptap/starter-kit'
import {Placeholder} from '@tiptap/extension-placeholder'
import Focus from '@tiptap/extension-focus'

import {TextColor} from './text_color'
import {Image} from './image'
import {Link} from './link'
import {ContentPlaceholder} from './content_placeholder'
import {SlashCommand} from './slash_command'
import {Column, Columns} from './columns'
import {Figure} from './figure'
import {Figcaption} from './figcaption'
import {YouTube} from './youtube'
import {TableCell, TableHeader, TableRow} from './table'
import {Highlight} from './highlight'
import {HorizontalRule} from './horizontal_rule'

import {
	textColorConfig,
	highlightConfig,
	imageConfig,
	linkConfig,
	// taskItemConfig,
	textAlignConfig,
	youtubeConfig,
	tableConfig,
	starterKitConfig,
	placeholderConfig,
	slashCommandConfig,
	focusConfig,
} from './configurations'

// adding extension configurations here, to have translations inside the render
export const getExtensions = () => {
	return [
		TextColor.configure(textColorConfig),
		Highlight.configure(highlightConfig),
		Image.configure(imageConfig),
		Link.configure(linkConfig),
		ContentPlaceholder,
		// not adding tasklist and -item (todo items) due to lack of styling options in mobile app
		// TaskList,
		// TaskItem.configure(taskItemConfig),
		TextStyle,
		TextAlign.configure(textAlignConfig),
		Underline,
		YouTube.configure(youtubeConfig),
		Table.configure(tableConfig),
		TableCell,
		TableHeader,
		TableRow,
		StarterKit.configure(starterKitConfig),
		Placeholder.configure(placeholderConfig),
		SlashCommand.configure(slashCommandConfig),
		Column,
		Columns,
		Figure,
		Figcaption,
		Focus.configure(focusConfig),
		HorizontalRule,
	]
}
