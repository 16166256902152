import {HelpButton} from 'common/components'

const docsLinks = [
	{id: 9909529, t: 'planning_basics'},
	{id: 6009484, t: 'publish_shift'},
	{id: 6025153, t: 'report_sick'},
	{id: 6009813, t: 'export_planning'},
]

export const Help = props => {
	return (
		<HelpButton
			{...props}
			tourId={562622}
			supportFilmId="planning_variants"
			tourRole="manager"
			docsLinks={docsLinks}
			collection={3355109}
		/>
	)
}
