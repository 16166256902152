import {useCallback, useState, useEffect} from 'react'

export const useDocumentWidth = documentContainerRef => {
	const [width, setWidth] = useState(0)

	const updateWidth = useCallback(() => {
		if (documentContainerRef.current) {
			const {offsetWidth, clientWidth} = documentContainerRef.current
			const scrollbarWidth = offsetWidth - clientWidth
			const effectiveWidth = offsetWidth - scrollbarWidth
			setWidth(effectiveWidth)
		}
	}, [])

	useEffect(() => {
		updateWidth()

		window.addEventListener('resize', updateWidth)

		return () => {
			window.removeEventListener('resize', updateWidth)
		}
	}, [updateWidth])

	return {width, updateWidth}
}
