import {forwardRef} from 'react'
import {Layout, Text} from 'common/components'
import {Icon} from '@eitje/web_components'

export const MenuItem = forwardRef(({icon, t, onClick, active, borderBottom}, ref) => {
	return (
		<Layout ref={ref} active={active} onClick={onClick} padding="10 12" width="full" gap={12} colorSet borderBottom={borderBottom}>
			<Icon name={icon} iconLibrary="font-awesome" />
			<Text t={t} />
		</Layout>
	)
})
