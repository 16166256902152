import {logout} from 'actions/auth'
import {isProd} from 'constants/general'
import {useChatWindowContext, useTotalUnreadMessagesCount} from 'cores/chat'
import {EXPORT_ROLES} from 'cores/exports2/constants'
import {onboardingRole, useOnboardingActive} from 'cores/onboarding'
import {pathActive} from 'helpers'
import {getUrl, useEnvSetting, useHasRole, useLocal, useLocalValue, useShared} from 'hooks'
import {useOrgEnvSetting} from 'hooks/use_env_setting'
import {t} from 'initializers/i18n'
import {setupCanny} from 'lib/canny'
import {BaseConnection} from 'models'
import {useSelector} from 'react-redux'
import {selfSelector} from 'selectors/records'
import {pendingVerzoekenSel} from 'selectors/verlof'

function toggleTranslations() {
	window.ignoreTranslations = !window.ignoreTranslations
}

export const useHeaderTax = () => {
	const {env, org} = useShared()
	const user = useSelector(selfSelector)
	const showDebug = useLocalValue('internalShowDebug')
	const toggleDebug = useLocal('internalShowDebug').toggle
	const fooksInteg = BaseConnection.find({sanitized_type: 'fooks'})
	const toggleDebugMode = useLocal('debugMode').toggle
	const hasOnboardingRole = useHasRole(onboardingRole)

	// ROLES
	const envHoursNotActive = !useEnvSetting('uren_reg', 'actief', env.id)
	const orgHoursNotActive = useOrgEnvSetting('uren_reg', 'actief').every(e => !e)
	const avDisabled = useOrgEnvSetting('beschikbaarheid', 'disabled').every(Boolean)
	const disableHours = !useHasRole('uren_schrijven')
	const disableExports = EXPORT_ROLES.map(role => useHasRole(role)).every(e => !e)
	const readyMadeExportRoles = ['manager', 'financieel', 'uren_accorderen']
	const disableReadyMadeExports = !readyMadeExportRoles.map(role => useHasRole(role)).some(Boolean)

	// COUNTS
	const pendingAmount = useSelector(pendingVerzoekenSel)
	const pendingLeaveCount = useHasRole('verlof') && pendingAmount?.length
	const unreadChatCount = useTotalUnreadMessagesCount()

	// CLICKS & REDIRECTS
	const onboardingDocsId = useHasRole('admin') ? '5106322' : '5106320'
	const onboardingDocsUrl = `${getUrl(true)}/${onboardingDocsId}`
	const {openChatWindow} = useChatWindowContext()

	// Reshuffle support items. The goal is to make the click on the nav item itself (so not the dropdown) more logical.
	// The NavItem click always uses the link of the first child in the taxonomy.
	const onboardingActive = useOnboardingActive()
	let supportItems = [
		{key: 'collections', link: '/support/collections'},
		{key: 'welcome', link: '/onboarding/welcome', role: onboardingRole},
	]
	if (onboardingActive) supportItems = supportItems.reverse()

	return [
		{
			key: 'planning',
			count: pendingLeaveCount,
			items: [
				{key: 'planning_per_team', link: '/planning/per_team'},
				{key: 'planning_per_member', link: '/planning/per_member'},
				{key: 'planning_table', link: '/planning_table'},
				{key: 'availability', link: '/availability', role: 'manager', disabled: avDisabled && 'availability', abbreviatedLabel: true},
				{key: 'my_availability', link: `/user/${user?.id}/profile`, disabled: avDisabled && 'availability', abbreviatedLabel: true},
				{key: 'leave', link: '/leave', disabled: orgHoursNotActive && 'time_registration', count: pendingLeaveCount},
			],
		},
		{
			key: 'time_registration',
			items: [
				{hide: !disableHours, key: 'my_hours', link: '/user_uren'},
				{
					key: 'hours_per_week',
					link: '/hours/per_week',
					disabled: orgHoursNotActive && 'time_registration',
					role: 'uren_schrijven',
				},
				{
					key: 'hours_per_member',
					link: '/hours/per_member',
					disabled: orgHoursNotActive && 'time_registration',
					role: 'uren_schrijven',
				},
				{
					key: 'hours_balance',
					link: '/overzicht?plus_min=true',
					disabled: envHoursNotActive && 'time_registration',
					role: 'uren_accorderen',
					role_in: 'current_env',
				},
				{
					key: 'leave_balance',
					link: '/overzicht?verlof=true',
					disabled: envHoursNotActive && 'time_registration',
					role: 'uren_accorderen',
					role_in: 'current_env',
				},
			],
		},
		{
			key: 'team',
			items: [
				{key: 'team_members', link: `/users/in_service`},
				{key: 'invite_team_members', modalLink: `/forms/invite`, role: 'manager'},
				{key: 'create_team', modalLink: `/team/new`, role: 'manager'},
				{key: 'profile', link: `/user/${user?.id}`},
				{
					hide: !fooksInteg.id,
					rawText: t('modals.integrations.export_users.title', {integName: 'Fooks'}),
					modalLink: fooksInteg?.exportUsersUrl?.(),
				},
				{key: 'firms', modalLink: `/environments`, role: 'manager'},
			],
		},
		{
			key: 'social',
			count: unreadChatCount,
			items: [
				{key: 'news', link: '/news'},
				{
					key: 'chat_modular',
					count: unreadChatCount,
					onClick: openChatWindow,
					disabled: pathActive('/chat') && 'chat_popup_on_chat_page',
				},
				{key: 'chat', link: '/chat', count: unreadChatCount},
			],
		},
		{
			key: 'training',
			items: [
				{key: 'topics', link: '/topics'},
				{key: 'skills', link: '/skills', role: 'manager'},
			],
		},
		{
			key: 'data',
			items: [
				{key: 'exports', link: '/exports2/tables', disabled: disableExports && 'export_role', abbreviatedLabel: true},
				{key: 'ready_made_exports', modalLink: '/exports', disabled: disableReadyMadeExports && 'ready_made_export_role'},
				{key: 'integrations', link: '/integrations', role: 'admin'},
			],
		},
		{
			key: 'help',
			onClick: !hasOnboardingRole && (() => window.open(onboardingDocsUrl, '_blank')),
			items: supportItems,
		},
		{
			key: 'settings',
			icon: 'three-lines',
			dropdownProps: {
				visibleAfterClick: true,
				onVisibleChange: setupCanny,
				extraHoverElementSelector: '#canny-changelog-iframe',
				delayedHovering: true,
			},
			items: [
				{key: 'firm_settings', link: '/bedrijf', role: 'admin', role_in: 'current_env'},
				{key: 'personal_settings', modalLink: `/forms/user_preferences/${user?.id}`},
				{key: 'common.check_in_system', link: `/bedrijf?vloer=true`, role: 'admin', role_in: 'current_env'},
				{key: 'product_updates', parentLayoutProps: {'data-canny-changelog': 'true'}, skipHandleClick: true},
				{key: 'invoices', link: '/billing/invoices', role: 'admin'},
				{key: 'logout', onClick: logout},
				!isProd && !showDebug && {key: 'hide_debug', onClick: toggleDebug},
			],
		},
		{
			key: 'debug',
			icon: 'bug',
			items: [
				{rawText: `user: ${user?.full_name} (${user?.id})`},
				{rawText: `env: ${env.naam} (${env.id})`},
				{rawText: `org: ${org.name} (${org.id})`},
				{rawText: 'dev page', link: '/dev/form'},
				{key: 'switch_translations', onClick: toggleTranslations},
				{key: 'debug_mode', onClick: toggleDebugMode},
				{key: 'hide_debug', onClick: toggleDebug},
			],
		},
	]
}
