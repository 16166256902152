import {useCallback, useRef, useState} from 'react'
import api from 'initializers/api'
import {ButtonBar} from 'common/components'
import {Icon} from '@eitje/web_components'
import {NAMESPACE} from 'common/components/rich_text_editor'
import {NodeViewWrapper} from '@tiptap/react'

export const ImageNodeView = ({getPos, editor, deleteNode}) => {
	const handleUpload = useCallback(
		url => {
			if (url) {
				const pos = getPos()
				editor.chain().setImage({src: url}).deleteRange({from: pos, to: pos}).focus().run()
			}
		},
		[editor, getPos],
	)

	const handleRemove = useCallback(
		e => {
			e.stopPropagation()
			deleteNode()
		},
		[deleteNode],
	)

	return (
		<NodeViewWrapper>
			<ImageUploader onUpload={handleUpload} onRemove={handleRemove} />
		</NodeViewWrapper>
	)
}

const ImageUploader = ({onUpload, onRemove}) => {
	const {loading, uploadFile} = useUploader({onUpload})
	const {handleUploadClick, ref} = useFileUpload()
	const onFileChange = useCallback(
		e => {
			const file = e.target.files?.[0]
			if (file) uploadFile(file)
		},
		[uploadFile],
	)

	return (
		<>
			<ButtonBar
				iconLeft="picture"
				loading={loading}
				t={`${NAMESPACE}.upload_image`}
				onClick={handleUploadClick}
				colorSet="color-bordered-color-content"
				iconRight={<Icon name="cross" onClick={onRemove} />}
			/>
			<input
				ref={ref}
				type="file"
				accept=".jpg,.jpeg,.png,.webp,.gif"
				onChange={onFileChange}
				style={{
					width: 0,
					height: 0,
					opacity: 0,
				}}
			/>
		</>
	)
}

const uploadImage = async file => {
	const headers = {'Content-Type': 'multipart/form-data'}
	const formData = new FormData()
	formData.append('file', file)
	const res = await api.post('/files/upload_redactor', formData, headers)
	return res.data
}

const useFileUpload = () => {
	const fileInput = useRef()

	const handleUploadClick = useCallback(() => {
		fileInput.current?.click()
	}, [])

	return {ref: fileInput, handleUploadClick}
}

const useUploader = ({onUpload}) => {
	const [loading, setLoading] = useState(false)

	const uploadFile = useCallback(
		async file => {
			setLoading(true)
			const res = await uploadImage(file)
			setLoading(false)

			// upload endpoint returns an awkward format
			const url = Object.values(res)[0].url
			onUpload(url)
		},
		[onUpload],
	)

	return {loading, uploadFile}
}
