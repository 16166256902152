import ApplicationRecord from './active_record/base'

import PlanningShift from './planning_shift'
import TimeRegistrationShift from './time_registration_shift'
import WeatherDay from './weather_day'
import Event from './event'
import RevenueDay from './revenue_day'
import RevenueGroup from './revenue_group'
import RevenueTeam from './revenue_team'
import PlanningTemplate from './planning_template'
import BaseConnection from './base_connection'
import LeaveRequest from './leave_request'
import Allowance from './allowance'
import UserWeek from './user_week'
import Day from './day'
import ContractHolder from './contract_holder'
import ShiftType from './shift_type'
import Association from './active_record/association'
import SickPeriod from './sick_period'
import ExtEnvironment from './ext_environment'
import ExtRevenueGroup from './ext_revenue_group'
import CheckIn from './check_in'
import TradeRequest from './trade_request'
import Info from './info'
import Invoice from './invoice'
import Team from './team'
import User from './user'
import Environment from './environment'
import Operation from './operation'
import SkillSet from './skill_set'
import Topic from './topic'
import AvailabilityShift from './availability_shift'
import {ExportTable, Excel, ExportSubscription} from 'cores/exports2/models/index'

const modelArray = [
	User,
	Event,
	UserWeek,
	AvailabilityShift,
	ContractHolder,
	RevenueDay,
	PlanningShift,
	PlanningTemplate,
	LeaveRequest,
	TimeRegistrationShift,
	WeatherDay,
	RevenueGroup,
	SickPeriod,
	RevenueTeam,
	BaseConnection,
	ExtEnvironment,
	Operation,
	ExtRevenueGroup,
	CheckIn,
	TradeRequest,
	Info,
	Invoice,
	Allowance,
	ShiftType,
	Day,
	Team,
	Topic,
	ShiftType,
	Environment,
	SkillSet,
	ExportTable,
	Excel,
	ExportSubscription,
]

let models = {}
modelArray.forEach(m => (models[m.tableName] = m))

export default models

export {
	User,
	UserWeek,
	ApplicationRecord,
	ExportTable,
	Association,
	PlanningShift,
	TimeRegistrationShift,
	PlanningTemplate,
	ContractHolder,
	WeatherDay,
	Event,
	RevenueDay,
	LeaveRequest,
	Operation,
	RevenueGroup,
	RevenueTeam,
	SickPeriod,
	AvailabilityShift,
	BaseConnection,
	ExtEnvironment,
	ExtRevenueGroup,
	TradeRequest,
	Allowance,
	CheckIn,
	Excel,
	ExportSubscription,
	Info,
	Invoice,
	ShiftType,
	Day,
	Team,
	Topic,
	Environment,
	SkillSet,
}
