import {all, API, find} from '@eitje/easy_api'
import utils from '@eitje/utils'
import {Button} from 'antd'
import Dropdown from 'components/dropdown'
import {ModalLink} from 'components/routing'
import {HoverButton, UserAvatar} from 'components/ui'
import {findTopicSchedState, getDaysToStart, onDragEnd} from 'helpers/topics'
import useList from 'hooks/use_list'
import useOutsideClick from 'hooks/use_outside_click'
import useParams from 'hooks/use_params'
import {t} from 'initializers/i18n'
import _ from 'lodash'
import {AssetButton} from 'pages/v3/news/components'
import React, {useRef} from 'react'
import {useSelector} from 'react-redux'
import {Redirect} from 'react-router-dom'
import {anyEnvRoleSelector} from 'selectors/new_settings'
import {whereInc} from 'selectors/records'
import {ArticleDataDisplay} from '../articles/components'
import {QuizItem} from '../quiz_items/components'
import '../styles/topics.less'
import {TopicDetails} from './components'
import Header from './header'
import useTopicResources from './use_topic_resources'
import '../index.less'
import './styles/show.less'
import {EitjeButton, Layout, ListPicker, PageLayout} from 'common/components'
import {CreateButton} from 'common/components/eitje_button/eitje_buttons'

const CreateDropdownButton = ({topicId, Component = Button}) => {
	const links = [
		<ModalLink to={`/articles/new?topic_id=${topicId}`} t="createArticle" />,
		<ModalLink to={`quiz_items/new`} t="createQuiz" />,
	]

	return (
		<Dropdown elements={links}>
			<Component type="primary">{t('create')}</Component>
		</Dropdown>
	)
}

const searchProps = {
	searchField: 'full_name',
}

const UserRow = ({item}) => {
	return (
		<>
			<UserAvatar user={item} className="margin-right-xss" />

			<div className="list-item-text-container">
				<p className="list-item-title">{item.full_name}</p>
			</div>
		</>
	)
}

const TopicTrainCenter = ({topicId}) => {
	const topic = useSelector(state => find(state, 'topics', topicId))
	const {publishedTopicResources = []} = useTopicResources(topic)
	const validDate = topic.valid_untill && utils.fullHumanYearDate(topic.valid_untill)
	const users = useSelector(state => whereInc(state, 'users', {team_ids: topic.team_ids}))
	const promptBox = useRef(null)
	const isSetup = findTopicSchedState(topic) !== 'no_training'
	const subtext = t(findTopicSchedState(topic) + '_infoBox', {date: validDate, count: getDaysToStart({number: topic.days_to_start})})

	useOutsideClick(promptBox, () => setVisible(false), {classNames: ['seachInput']})

	const MenuTrigger = props => (
		<AssetButton {...props} toolText={t('trainingPromptSubhead')} iconSrc="/images/web/icons/popoutWindowIcon.png" />
	)

	const handleUserRowClick = user => API.create('user_topic_prompt', {topic_id: topic.id, user_id: user.id})

	const {
		list: dropdownList,
		trigger,
		setVisible,
		searchInput,
	} = useList({
		items: users,
		itemProps: {topic},
		Trigger: MenuTrigger,
		ListItem: UserRow,
		onClick: handleUserRowClick,
		dropdown: true,
		searchProps,
	})

	return (
		<div className="training-overview-box column-with-border-and-padding-sm training-container">
			<div className="training-textual-information vertical-padding-sm margin-bottom-sm">
				<h3>{t('common.training')}</h3>
				<p className="eitje-text-secondary">{t('controlCenter')}</p>

				<div className="eitje-text-secondary">{subtext || ''}</div>
			</div>

			<div className="analytics-links">
				{topic.published && <EitjeButton width="full" modalLink="training_overview" t="analytics" />}

				<div className="training-for-users-list" style={{position: 'relative'}}>
					{topic.published && trigger}
					{searchInput && (
						<div className="popover user-list">
							<div ref={promptBox} className="popover-contents">
								<h3>{t('start_training')}</h3>
								<h5>{t('trainingPromptSubhead')}</h5>
								{searchInput}
								<div className="list-of-users user-list-sm">{dropdownList}</div>
							</div>
						</div>
					)}
				</div>
			</div>

			<Layout width="100%" childrenEqual>
				<EitjeButton colorSet={!isSetup ? 'solid' : true} width="full" modalLink="training_setup" t={isSetup ? 'edit' : 'setup'} />

				{publishedTopicResources.length > 0 && <EitjeButton width="full" modalLink="test_run" t="testRun" />}
			</Layout>
		</div>
	)
}

const Topic = () => {
	const {id} = useParams()
	const topic = useSelector(state => find(state, 'topics', id))

	const {topicInfos, topicResources} = useTopicResources(topic)
	const isManager = useSelector(state => anyEnvRoleSelector(state, 'manager', topic.environment_ids))
	const selectRef = useRef(null)

	const infos = useSelector(state => all(state, 'infos'))
	const infoIds = topicInfos.map(i => i.id)
	const otherInfos = infos.filter(i => !infoIds.includes(i.id))

	const filterResourcesForBasicUser = topicResources.filter(item => !item.question)
	const items = isManager ? topicResources : filterResourcesForBasicUser

	if (_.isEmpty(topic)) {
		return <Redirect push to="/topics" />
	}

	const placeholderProps = {
		name: 'article',
		animation: 'notebook',
		docsId: '3355101',
		children: [
			<CreateButton modalLink={`/articles/new?topic_id=${id}`} t="action_button" />,
			<CreateButton modalLink="quiz_items/new" blockNamespace t="createQuiz" />,
		],
		collection: true,
		size: 'default',
	}

	const header = <Header topic={topic} RightButton={CreateDropdownButton} returnToTopics />

	return (
		<PageLayout name="topic-show" headers={header}>
			<div className="row-with-justify-center">
				<div className="topic-container wide" id="show-topic-page">
					<div className="row-with-justify-start gap-xl">
						{/* left Container of the Topic Settings / Controls */}
						<div style={{width: '268px'}} className="topic-settings-column">
							{/* Topic details contains the form that updates the Topic record PUBLISHED && TEAM_IDS */}
							<div className="margin-bottom-xs">
								<TopicDetails isManager={isManager} item={topic} />
							</div>
							{isManager && <TopicTrainCenter topicId={id} />}
						</div>

						{/* Main Container of the Articles / Questions rows */}
						<div className="very-large-padding-bottom" style={{width: '80%'}}>
							<ArticleDataDisplay
								sortField="order"
								sortEnabled={false}
								CustomRow={QuizItem}
								hasCustomRow={item => !!item.answers}
								direction="vertical"
								actions={isManager}
								onDragEnd={onDragEnd}
								draggable={isManager}
								items={items}
								placeholderProps={placeholderProps}
							/>

							{isManager && (
								<div className="article-list-footer">
									{otherInfos.length > 0 && (
										<ListPicker
											raw
											dropdown
											single
											Trigger={props => <HoverButton size="md" ref={selectRef} {...props} children={t('selectArticle')} />}
											showSearch
											onChange={infoId => API.addAssoc('topics', {info_ids: [infoId], id})}
											labelField="title"
											field="info_ids"
											items={otherInfos}
										/>
									)}
									{!!items.length && <CreateDropdownButton topicId={id} Component={props => <HoverButton size="md" {...props} />} />}
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</PageLayout>
	)
}

export default Topic
