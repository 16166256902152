import {useNoNotificationsModus, EnableNotificationsPopout} from 'cores/onboarding'
import {useUnpaidInvoices, useBillingDone} from 'cores/billing'
import {useHasRole, useArbitrary, useShared} from 'hooks'
import {storeApplicationHeaders} from '..'

// We want to give first time users a smooth as possible experience, unhindered by a pressure to sign and thus unnecessary UI
// components. Therefore only show the setup billing header when the first billing date is somewhat nearer.
const REMAINING_FREE_DAYS_THRESHOLD = 21

export const useMessageHeadersTaxonomy = () => {
	const {orgEnvs} = useShared()
	const eitjeEnv = orgEnvs[0].status === 'eitje'
	const admin = useHasRole('admin')
	const userAdmin = admin && !eitjeEnv
	const noNotifications = useNoNotificationsModus() && admin
	const {unpaidCount, overdueCount} = useUnpaidInvoices()
	const payOverdueInvoices = overdueCount && userAdmin
	const billingPending = !useBillingDone()
	const {days_remaining, locked} = useArbitrary('orgStatus') || {}
	const showBilling = days_remaining <= REMAINING_FREE_DAYS_THRESHOLD
	const trialActive = days_remaining >= 0 // decreases by 1 each day of lifetime...
	const setupPaymentInfo = billingPending && !locked && trialActive && days_remaining && showBilling && userAdmin
	// Always show setup Direct Debit (much better for our administration than manually checking which invoices has been paid), except when
	// the user has to setup billing for the very first time.
	const setupDirectDebit = billingPending && showBilling && !trialActive && !setupPaymentInfo && userAdmin

	const taxonomy = [
		noNotifications && {
			id: 'no_notifications_modus',
			baseColor: 'blue',
			Wrapper: EnableNotificationsPopout,
		},
		setupPaymentInfo && {
			id: 'setup_billing',
			baseColor: 'grey',
			modalLink: '/billing/setup',
			labelProps: {daysRemaing: days_remaining},
		},
		setupDirectDebit && {
			id: 'setup_direct_debit',
			baseColor: 'grey',
			modalLink: '/billing/setup',
		},
		payOverdueInvoices && {
			id: 'unpaid_invoices',
			// Showing or not showing the message header should be determined by the overdueCount, but once shown, the number should
			// be the total unpaidCount, to prevent inconsistencies in numbers between the header and the page.
			labelProps: {unpaidAmount: unpaidCount},
			baseColor: 'red',
			to: '/billing/invoices',
		},
	].filter(Boolean)

	// We store which application wide headers are visible in order to read this state
	// in the page's context. This way, we may rerender the page Layout more efficiently
	// than in the previous case where the PageLayout was listing to the URL with useLocation,
	// thus rendering the page each time you would open or close a modal. This was very
	// unperformant on slow pages like the AdvancedTable pages.
	const headerNames = ['navigation_header', ...taxonomy._map('id')].sort()
	storeApplicationHeaders(headerNames)

	return taxonomy
}
