import {useEffect, useLayoutEffect, useState} from 'react'
import {Icon, PopoutCard} from '@eitje/web_components'
import _ from 'lodash'
import {t} from 'initializers/i18n'
import {ftime} from 'helpers'
import {useRichTextEditorContext, NAMESPACE} from 'common/components/rich_text_editor'

const SAVING_DEBOUNCE_WAIT = 1000

export const SavingIndicator = () => {
	const {editor, updatedAt, onSave} = useRichTextEditorContext()
	const [isSaving, setIsSaving] = useState(false)

	useEffect(() => {
		const debouncedSave = _.debounce(async editor => {
			await onSave(editor?.getHTML())
			setIsSaving(false)
		}, SAVING_DEBOUNCE_WAIT)

		const onUpdate = ({editor}) => {
			setIsSaving(true)
			debouncedSave(editor)
		}

		editor.on('update', onUpdate)

		return () => {
			// throw away any pending invocation of the debounced function
			// to prevent state updates on unmounted components
			debouncedSave.cancel()
			editor.off('update', onUpdate)
		}
	}, [onSave]) // reference of onSave can change after permission checks, so including in dep array

	useLayoutEffect(
		() => () => {
			// using a layout effect to save the latest editor state,
			// it runs during unmount, but before the element has actually left the page.
			onSave(editor?.getHTML())
		},
		[],
	)

	const savedAt = updatedAt && ftime(updatedAt, 'dateTime')
	const title = isSaving ? t(`${NAMESPACE}.saving`) : savedAt

	return (
		<PopoutCard layoutProps={{padding: '0 8'}} title={title}>
			{isSaving && <Icon rotatingAnimation name="circle-line-part" iconLibrary="font-awesome" fill={Colors.mediumBlue} />}
			{!isSaving && savedAt && <Icon name="checked" iconLibrary="font-awesome" fill={Colors.darkGreen} />}
		</PopoutCard>
	)
}
