import {createContext, useContext, useCallback, useMemo, useRef} from 'react'
import {useArticleRoleMessage} from 'cores/knowledge'

const ArticleContext = createContext()
const {Provider} = ArticleContext

export const ArticleProvider = ({article, children}) => {
	const updateRef = useRef()
	const editDisabled = useArticleRoleMessage(article)

	// assigning update function to a ref here, because we are calling this method when unmounting the page
	// there is and edge-case that when an article is destroyed and we navigate to the index page, this method
	// is also called. But the resource is gone at that time, so we need to make sure to not call an update on
	// the destroyed resource.
	updateRef.current = {id: article.id, update: article.update}

	const updateArticle = useCallback(
		async data => {
			// only update if edit is enabled and the ref contains the update method of the current article
			if (editDisabled || !updateRef.current || updateRef.current.id !== article.id) return
			return await updateRef.current.update(data, {
				extraParams: {skipSuccessNotif: true, doNotLoad: true},
			})
		},
		[article, editDisabled],
	)

	const {pdf_content, pdf_leading} = article
	const isPDF = !!pdf_content && pdf_leading
	const isRTE = !isPDF

	const state = useMemo(() => ({article, editDisabled, updateArticle, isPDF, isRTE}), [article, editDisabled, updateArticle, isPDF, isRTE])

	const noArticle = _.isEmpty(article)
	if (noArticle) return null

	return <Provider value={state} children={children} />
}

export const useArticleContext = () => useContext(ArticleContext)
