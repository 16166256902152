import {Input} from '@eitje/form-fields-web'
import {passwordRequirements} from 'cores/auth'
import {t} from 'initializers/i18n'
import {isEmail, isPass} from 'lib/form/validation'

const validatePassword = (val, {getFormData}) => {
	if (!isPass(val)) return passwordRequirements()
}

const validatePasswordConfirm = (val, {getFormData}) => {
	const {password, password_confirmation} = getFormData()
	if (password && password_confirmation && password != password_confirmation) return t('sysPassConf')
}

const sharedProps = {
	secure: true,
	required: true,
}

export const PasswordInput = props => {
	return <Input field="password" validate={validatePassword} {...sharedProps} {...props} />
}

export const PasswordConfirmInput = props => {
	return <Input field="password_confirmation" validate={validatePasswordConfirm} {...sharedProps} {...props} />
}

export const validateEmail = email => {
	if (!isEmail(email)) return t(`form.general.validation.email`)
}

export const EmailInput = props => {
	return <Input field="email" required validate={validateEmail} {...props} />
}

// From DOMPurify
// https://github.com/cure53/DOMPurify/blob/main/src/regexp.ts
// eslint-disable-next-line no-control-regex
const ATTR_WHITESPACE = /[\u0000-\u0020\u00A0\u1680\u180E\u2000-\u2029\u205F\u3000]/g
const allowedProtocols = ['http', 'https', 'ftp', 'ftps', 'mailto', 'tel', 'callto', 'sms', 'cid', 'xmpp']
const protocolRegex = new RegExp(`^(${allowedProtocols.join('|')}):`, 'i')
const domainRegex = /^(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/

// Function to add default "https://" if URI looks like a domain
export const addDefaultHttpProtocol = uri => {
	if (!protocolRegex.test(uri) && domainRegex.test(uri)) {
		return `https://${uri}`
	}
	return uri
}
const validateUri = uri => {
	const validationMessage = t('form.general.validation.uri')
	// Ensure URI is defined and not just whitespace
	if (!uri || uri.replace(ATTR_WHITESPACE, '') === '') return validationMessage

	let sanitizedUri = uri?.replace(ATTR_WHITESPACE, '')

	// Check if uri contains spaces
	if (uri.length !== sanitizedUri.length) return validationMessage

	// Add default "https://" if applicable
	sanitizedUri = addDefaultHttpProtocol(sanitizedUri)

	// Validate the protocol
	if (!protocolRegex.test(sanitizedUri)) return validationMessage

	try {
		// Ensure the URI is a valid URL
		new URL(sanitizedUri)
		return true
	} catch (e) {
		return validationMessage
	}
}

export const URIInput = props => {
	return <Input field="uri" required validate={validateUri} {...props} />
}
