import {useMemo} from 'react'
import utils from '@eitje/web_utils'
import {LeaveRequest, Association, User} from 'models'
import {useLeaveDates} from 'cores/leave'
import {useEnvsContext} from '../providers/envs_provider'
import {useSearchContext} from 'contexts/search'
import {useFilterFooterContext} from 'common/components'
import {useRoleEnvs, filterItems as searchItems, useShared, useRoleOrgEnvsUsersWithDel} from 'hooks'
import {log} from 'helpers'

const opts = {joins: ['users', 'environments']}

const filterShifts = (items, search) => {
	return searchItems(items, search, {searchField: 'user.full_name'})
}

export const useFilteredLeave = () => {
	const {dateRange, startDate, endDate} = useLeaveDates()
	const {me} = useShared()
	const allowedUsers = useRoleOrgEnvsUsersWithDel('verlof')

	// Always view own items + show items from users that are in env where you have leave role
	const allItems = LeaveRequest.where({dateRange, user_id: [me.id, ...allowedUsers.ids()]})

	// Filtered by search
	const {search} = useSearchContext()
	const searchItems = useMemo(() => filterShifts(allItems, search), [allItems, search])

	return {items: searchItems}
}
