import {useMemo} from 'react'
import {Text} from 'common/components'
import {useRichTextEditorContext, COMMANDS} from 'common/components/rich_text_editor'

const iconProps = {iconLibrary: 'font-awesome'}

export const useCommandButtonState = commandNames => {
	const {editor} = useRichTextEditorContext()

	const isMultipleCommands = Array.isArray(commandNames)
	const commandNamesArray = isMultipleCommands ? commandNames : [commandNames]
	const commands = commandNamesArray.map(commandName => COMMANDS[commandName])

	/**
	 * Function to process a single command and return its items.
	 * Handles commands with or without options.
	 */
	const getCommandItems = command => {
		const {commandFn, icon, isActive, label, options, isDisabled} = command

		if (options) {
			// For commands with options, map over options and create items
			return options.map(({label, icon, isActive, commandFn: optionCommandFn}) => ({
				label,
				iconLeft: icon,
				active: isActive(editor),
				onClick: () => optionCommandFn(editor),
				disabled: isDisabled(editor),
			}))
		}

		// For commands without options, return a single item
		return [
			{
				label,
				iconLeft: icon,
				active: isActive(editor),
				onClick: () => commandFn(editor),
				popoutTitle: label,
				disabled: isDisabled(editor),
			},
		]
	}

	const commandItems = useMemo(() => commands.flatMap(getCommandItems), [commands])
	const popoutTitle = !isMultipleCommands && commands[0].label

	// Build elements for multiple command dropdowns
	const elements = useMemo(() => {
		if (commandItems.length <= 1) return null

		return commandItems.map(({iconLeft, onClick, label}) => ({
			icon: iconLeft,
			iconProps,
			onClick,
			element: <Text t={label} />,
		}))
	}, [commandItems])

	return {commandItems, popoutTitle, elements}
}
