import {EitjeDropdown} from 'common/components'
import {BaseButton} from './base_button'
import {useCommandButtonState} from './hooks'

export const CommandButton = ({commandNames, t, type}) => {
	const {commandItems, popoutTitle, elements} = useCommandButtonState(commandNames)

	if (!elements) return <BaseButton {...commandItems[0]} />

	const activeCommand = commandItems.find(item => item.active)
	const {label: activeLabel, iconLeft: activeIconLeft} = activeCommand || commandItems[0]
	const activeT = type !== 'iconOnly' && (t || activeLabel)
	const iconLeft = type !== 'textOnly' && activeIconLeft

	return (
		<EitjeDropdown placement="bottom-start" trigger="click" elements={elements}>
			<BaseButton iconRight="chevron-down" iconLeft={iconLeft} t={activeT} popoutTitle={popoutTitle} />
		</EitjeDropdown>
	)
}
