import TiptapImage from '@tiptap/extension-image'

import './styles/index.less'

const SIZES = {
	small: '33%',
	medium: '66%',
	large: '100%',
}

export const Image = TiptapImage.extend({
	addAttributes() {
		return {
			...this.parent?.(),
			width: {
				default: SIZES.large,
			},
		}
	},

	addCommands() {
		return {
			...this.parent?.(),
			setImageWidth:
				size =>
				({commands}) => {
					const width = this.storage[size]
					return commands.updateAttributes('image', {width})
				},
		}
	},

	addStorage() {
		return SIZES
	},
})
