import {t} from 'initializers/i18n'
import {useWhere} from '@eitje/easy_api'
import {PopoutCard} from '@eitje/web_components'
import {UserListPopout} from 'common/components'
import {useEnvContext} from '../contexts/env'
import utils from '@eitje/web_utils'
import '../../styles/availability.less'
import './day.less'
import usePlanningDay from 'cores/planning/hooks/use_planning_day'

const Day = ({date}) => {
	const {env} = useEnvContext()
	const {closed, condOpts} = usePlanningDay(date, env.id)
	const classNames = utils.makeCns('day-header', closed && 'day-header-closed', date.isToday() && 'day-header-active')

	return (
		<div {...condOpts} className={classNames}>
			{closed && <img className="lock-icon" src="/images/web/icons/lock_icon.png" width={12} height={12} />}

			<div className="day-header-text-container">
				<p className="day-header-title"> {date.format('dd DD MMM')} </p>
				<AvCount date={date} />
			</div>
		</div>
	)
}

const useAvUsers = params => {
	const userIds = useWhere('availabilityShifts', params)
		.map(u => u.user_id)
		.uniq()

	let users = useWhere('users', userIds)
	const leaveRequests = useWhere('leaveRequests', {dateRange: params.date, user_id: params.user_id, accepted: true})
	const sickPeriods = useWhere('sickPeriods', {dateRange: params.date, user_id: params.user_id})
	const leaveSickUserIds = [...leaveRequests, ...sickPeriods].map(u => u.user_id).uniq()

	const leaveSickUsers = useWhere('users', leaveSickUserIds)
	if (!params.available) {
		users = [...users, ...leaveSickUsers].uniq()
	} else {
		users = users.filter(u => !leaveSickUsers.includes(u))
	}

	users = _.orderBy(users, 'full_name')

	return {users, avCount: users.length, message: params.available ? 'unavailable' : 'available'}
}

const AvCount = ({date}) => {
	const {users, settings} = useEnvContext()

	const defaultUnav = settings.default == 'unavailable'
	const params = {date: date.format('YYYY-MM-DD'), user_id: users.map(u => u.id)}
	const totalCount = users.length

	const selectedAvObj = useAvUsers({...params, available: defaultUnav})
	const {avCount, message, users: avUsers} = selectedAvObj
	const count = totalCount - avCount

	const inverseMessage = message == 'available' ? 'unavailable' : 'available'

	return (
		<UserListPopout hidden={avUsers.length === 0} users={avUsers} t={inverseMessage} placement="bottom" width={250} maxHeight={250}>
			<p className="day-header-subtitle">
				{count}/{totalCount} {t(message)}
			</p>
		</UserListPopout>
	)
}

export default Day
