import {DatePicker} from '@eitje/form-fields-web'
import {DefaultListPickerTrigger} from '@eitje/web_components'
import {SickPeriod} from 'models/index'
import {useForm, ContextForm} from '@eitje/form'
import {useSchedulableOrgUsers} from 'hooks/use_schedulable_users'
import {FormSubmitButton} from 'components/form'
import {useRoleOrgEnvs} from 'hooks'
import {ListPicker} from 'common/components'
import './styles/new_sick_period.less'

const Page = () => {
	const users = useSchedulableOrgUsers()
	const managerEnvs = useRoleOrgEnvs('manager')
	const usersInCurrentUserEnvs = users.where({environment_ids: managerEnvs.ids()})

	return (
		<div className="new-sick-period">
			<ContextForm
				resetAfterSubmit
				onSubmit={data => SickPeriod.create(data)}
				transNamespace="new_sick_period"
				className="eitje-form-2-row-form eitje-form-2-ignore-styling"
			>
				<ListPicker
					Trigger={DefaultListPickerTrigger}
					decorated={false}
					dropdown
					single
					field="user_id"
					required
					items={usersInCurrentUserEnvs}
					labelField="full_name"
					height="small"
				/>
				<DatePicker required label={null} field="start" />
				<SubmitButton />
			</ContextForm>
		</div>
	)
}

const SubmitButton = () => {
	const {getData} = useForm()
	const {user_id, start} = getData() || {}

	return <FormSubmitButton height="small" disabled={!user_id || !start} />
}

export default Page
