const initialState = {
	planningForecast: true,
	planningPerMemberShowUserAvailability: true,
	planningQuickPlan: true,
	planningShowEvents: true,
	planningShowRemarks: true,
	planningShowShiftType: true,
	planningShowTrade: true,
	timeRegBundleRowsPeriod: true,
	planningShowImproductive: true,
	planningShowSkills: true,
	planningShowPlannedExpected: true,
	planningShowAvatar: true,
	planningShowHourRow: true,
	timeRegRevenue: true,

	// Planning WeekTableDaysHeader display values
	planningShowWeather: true,
	planningShowAvLock: true,

	// Hours WeekTableDaysHeader display values
	hoursShowTotalRegistered: true,
	hoursShowPlannedWorkedDiff: true,

	// Hours per week shift cards
	hoursPerMemberShowStartEnd: true,

	// Hours per week user card in row
	hoursUserWorked: true,
	hoursContractHours: true,

	// Internal
	internalShowDebug: true,
}

export default function reduce(state = initialState, action) {
	switch (action.type) {
		case 'TOGGLE_LOCAL_VALUE': {
			const newVal = !state[action.name]
			return {
				...state,
				[action.name]: newVal,
			}
		}
		case 'SET_LOCAL_VALUE':
			return {
				...state,
				[action.name]: action.value,
			}
		default:
			return state
	}
}
