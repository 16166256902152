import useSelector from 'hooks/use_selector'
import {Input, DatePicker, TimePicker} from '@eitje/form-fields-web'
import {useWhere} from '@eitje/easy_api'
import {FormRow} from 'components/ui'
import useShared from 'hooks/use_shared'
import {useFormData, useRoleOrgEnvsUsers} from 'hooks/index'
import {isTimeDisabled} from 'lib/form/helpers/'
import {roleOrgEnvsSelector} from 'selectors/index'
import {ListPicker, Tabs} from 'common/components'

const kinds = ['few_hours', 'single_day', 'multiple_days']

const dateProps = {startDateField: 'start_datetime', endDateField: 'end_datetime'}

const Page = () => {
	const {kind} = useFormData()

	const users = useRoleOrgEnvsUsers('verlof')
	const isMultipleDays = kind == 'multiple_days'
	return (
		<div className="leave-form-body eitje-form-2 eitje-form-2-use-borders">
			{users.length > 0 && (
				<FormRow>
					<ListPicker single dropdown required items={users} labelField="full_name" field="user_id" />
				</FormRow>
			)}

			<FormRow>
				<Tabs label={null} items={kinds} field="kind" />
			</FormRow>

			<FormRow>
				<DatePicker isStart={isMultipleDays} required field="start_datetime" {...dateProps} />
			</FormRow>

			{kind == 'few_hours' && (
				<FormRow>
					{/* isStart / isEnd / disabledTime isn't working because we're on antd 4.15, need to be at 4.19 at least, but we can't bump due to dropdown breaking  */}
					<TimePicker required isStart disabledTime={isTimeDisabled} field="start_time" />
					<TimePicker required isEnd disabledTime={isTimeDisabled} field="end_time" />
				</FormRow>
			)}

			{isMultipleDays && (
				<FormRow>
					<DatePicker required isEnd field="end_datetime" {...dateProps} />
				</FormRow>
			)}

			<FormRow>
				<Input field="reden" required />
			</FormRow>
		</div>
	)
}

export default Page
