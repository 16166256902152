import {EditorContent} from '@tiptap/react'
import {Layout} from 'common/components'
import {useRichTextEditorContext} from 'common/components/rich_text_editor'
import {
	DragHandleMenu,
	FloatingImageMenu,
	FloatingLinkMenu,
	FloatingYouTubeMenu,
	FloatingTableRowMenu,
	FloatingTableColumnMenu,
} from './menus'

const inlineStyles = {cursor: 'text'}

export const DocumentContent = () => {
	const {editor, documentContainerRef} = useRichTextEditorContext()

	if (!editor) return null

	// sets editor focus when the editor container is clicked
	const handleContainerClick = ({target, currentTarget}) => {
		if (!editor || target !== currentTarget) return
		if (!editor.isFocused) editor.commands.focus('end')
	}

	return (
		<Layout
			ref={documentContainerRef}
			className="eitje-rich-text-document-content"
			block
			grow
			relative
			vertical="start"
			onClick={handleContainerClick}
			style={inlineStyles}
		>
			<EditorContent editor={editor} className="eitje-rich-text-editor-content" />
			<DragHandleMenu />
			<FloatingLinkMenu />
			<FloatingYouTubeMenu />
			<FloatingImageMenu />
			<FloatingTableRowMenu />
			<FloatingTableColumnMenu />
		</Layout>
	)
}
