import {DocsPopout, EitjeButton} from 'common/components'
import {NAMESPACE} from 'cores/knowledge'

export const TypePopout = props => {
	return <DocsPopout docsId="6014511" {...props} />
}

export const TopicPopout = props => {
	const buttons = [<EitjeButton iconLeft="external-link" t={`${NAMESPACE}.view_topics`} link="/topics" />]
	return <DocsPopout docsId="6009233" buttons={buttons} {...props} />
}
