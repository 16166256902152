import {Input} from '@eitje/form-fields-web'
import {Topic} from 'models'
import {useFormData} from 'hooks'
import {t} from 'initializers/i18n'
import {ListPicker} from 'common/components'
import {NAMESPACE} from 'cores/knowledge'
import {FilePicker} from 'lib/form/fields/file_picker'
import {TypePopout, TopicPopout} from './popouts'
import {FormRow} from '@eitje/form-fields-web'

export const CreateFormFields = () => {
	const {type} = useFormData()
	const topics = Topic.all()

	const types = [
		{id: 'rte', label: t(`${NAMESPACE}.create_article.rte`)},
		{id: 'pdf', label: t(`${NAMESPACE}.create_article.pdf`)},
	]

	const showPdfInput = type === 'pdf'

	return (
		<>
			<FormRow>
				<Input field="title" required maxLength={75} />
			</FormRow>
			<FormRow>
				<ListPicker
					field="type"
					items={types}
					dropdown
					required
					labelField="label"
					single
					tooltip
					popoutBody
					PopoutComponent={TypePopout}
				/>
			</FormRow>

			{showPdfInput && (
				<FormRow>
					<FilePicker field="file" required accept="application/pdf" />
				</FormRow>
			)}
			<FormRow>
				<ListPicker
					field="topic_ids"
					items={topics}
					dropdown
					labelField="title"
					tooltip
					popoutBody
					PopoutComponent={TopicPopout}
					maxWidth={400}
				/>
			</FormRow>
		</>
	)
}
