import {API, backend} from '@eitje/easy_api'
import utils from '@eitje/web_utils'
import {updateSettings} from 'actions/settings'
import {Button, Modal, Select, Switch} from 'antd'
import {DeleteButton, EitjeButton, Layout, Placeholder, YouTube} from 'common/components'
import {t} from 'initializers/i18n'
import React from 'react'
import * as jsUtils from 'utils/jsutils'
import './styles/vloer_settings.less'
import {getSupportYouTubeId} from 'cores/support'
const Option = Select.Option

const Device = ({item, afterDel}) => {
	const del = async () => {
		const res = await API.destroy('authorized_devices', item.id)
		afterDel(item.id)
	}
	return (
		<Layout direction="vertical" horizontal="center" className="vloer-devices">
			<p>Status: {item.authorized ? `${t('active')}` : `${t('inactive')}`} </p>
			{item.authorized && (
				<p>
					{t('vloer4')}: {item.name}
				</p>
			)}
			<p>KEY ID: {item.vloer_key} </p>
			<p>SECRET ID: {item.vloer_secret} </p>
			<DeleteButton onDel={del} />
		</Layout>
	)
}

class VloerModal extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			roundAmt: jsUtils.isPresent(props.roundAmt) ? props.roundAmt : 5,
			roundType: props.roundType || 'round',
			end_round_amt: utils.exists(props.end_round_amt) ? props.end_round_amt : 5,
		}
	}

	submit = () => {
		const {roundAmt, roundType, end_round_amt} = this.state
		const {onCancel, onSucc} = this.props
		jsUtils.updateEnvSetting({vloer: {roundAmt, end_round_amt, roundType}})
		onSucc({roundAmt, roundType, end_round_amt})
	}

	render() {
		const {submit} = this
		const {onCancel} = this.props
		const {roundAmt, roundType, end_round_amt} = this.state
		const roundOpts = [0, 5, 10, 15]
		return (
			<Modal className="modalStyle" onOk={() => submit()} onCancel={() => onCancel()} title={t('vloer5')} visible>
				<div>
					<p>{t('vloer9')}:</p>
					<Button className={roundType == 'round' && 'active'} onClick={() => this.setState({roundType: 'round'})}>
						{t('vloer6')}
					</Button>
					<Button className={roundType == 'ceil' && 'active'} style={{marginLeft: 4}} onClick={() => this.setState({roundType: 'ceil'})}>
						{t('vloer7')}
					</Button>
					<Button className={roundType == 'floor' && 'active'} style={{marginLeft: 4}} onClick={() => this.setState({roundType: 'floor'})}>
						{t('vloer8')}
					</Button>

					<p style={{marginTop: 24}}>{t('vloer10')}:</p>

					{roundOpts.map(opt => (
						<div>
							<Button
								className={roundAmt == opt && 'active'}
								style={{float: 'left', width: 80, minWidth: 80, marginLeft: 4}}
								onClick={() => this.setState({roundAmt: opt})}
							>
								{' '}
								{opt}{' '}
							</Button>
						</div>
					))}
					<div className="clear" />

					<p style={{marginTop: 24}}>{t('floor.round_end')}:</p>

					{roundOpts.map(opt => (
						<div>
							<Button
								className={end_round_amt == opt && 'active'}
								style={{float: 'left', width: 80, minWidth: 80, marginLeft: 4}}
								onClick={() => this.setState({end_round_amt: opt})}
							>
								{' '}
								{opt}{' '}
							</Button>
						</div>
					))}
				</div>
			</Modal>
		)
	}
}

class DeviceModal extends React.Component {
	render() {
		const {devices, afterDel} = this.props
		const {createDevice, onCancel} = this.props

		return (
			<Modal className="modalStyle" onOk={() => onCancel()} onCancel={() => onCancel()} title={t('vloer1')} visible>
				<Button style={{width: '100%', marginTop: 8}} onClick={createDevice}>
					{t('vloer2')}
				</Button>

				<p style={{marginBottom: 8, marginTop: 24, fontWeight: '500'}}>
					{t('vloer3')} ({devices.length})
				</p>
				<div>
					{devices.map(d => (
						<Device afterDel={afterDel} item={d} />
					))}
				</div>
			</Modal>
		)
	}
}

const PauzeRule = ({item, change}) => (
	<div className="floatL">
		<Select placeholder={t('vloer13')} className="floatL" value={item.hours} style={{width: 150}} onChange={a => change(a, 'hours')}>
			{[4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map(num => (
				<Option value={num}>
					{' '}
					{t('vloer16')} {num} {t('vloer17')}{' '}
				</Option>
			))}
		</Select>

		<Select placeholder={t('vloer14')} value={item.pauze_amt} style={{width: 150, marginLeft: 4}} onChange={a => change(a, 'pauze_amt')}>
			{[15, 30].map(num => (
				<Option value={num}>
					+ {num} {t('vloer18')}{' '}
				</Option>
			))}
		</Select>
	</div>
)

class PauzeModal extends React.Component {
	constructor(props) {
		super(props)
		const rules = props.pauze_rules && props.pauze_rules.length > 0 ? this.managePauze(props.pauze_rules) : [{}, {}, {}]
		this.state = {
			pauze_rules: rules,
		}
	}

	managePauze(roles) {
		if (roles.length == 1) roles = [...roles, {}, {}]
		if (roles.length == 2) roles = [...roles, {}]
		return roles
	}

	submit = () => {
		const {pauze_rules} = this.state
		const pauzeRules = pauze_rules.filter(r => r.pauze_amt)
		jsUtils.updateEnvSetting({vloer: {pauze_rules: pauzeRules}})
		this.props.onSucc({pauze_rules})
	}

	update() {}

	del = pauzeRule => {
		const {pauze_rules} = this.state
		const nieuwe = [...pauze_rules]
		const idx = this.state.pauze_rules.indexOf(pauzeRule)
		nieuwe[idx] = {}
		this.setState({pauze_rules: nieuwe})
	}

	change = (rule, value, field) => {
		const pr = [...this.state.pauze_rules]
		const newRule = {...rule}
		newRule[field] = value
		const idx = pr.indexOf(rule)
		pr[idx] = newRule
		this.setState({pauze_rules: pr})
	}

	render() {
		const {submit, change, del} = this
		const {pauze_rules} = this.state
		const {onCancel} = this.props
		return (
			<Modal className="veetwee32" onOk={() => submit()} onCancel={() => onCancel()} title={t('vloer12')} visible>
				{pauze_rules.map(p => (
					<div style={{marginBottom: 12, padding: '4px 24px'}}>
						<PauzeRule item={p} change={(val, field) => change(p, val, field)} />
						<Button style={{marginLeft: 4}} onClick={() => del(p)}>
							{t('vloer15')}
						</Button>
						<div className="clear"></div>
					</div>
				))}
			</Modal>
		)
	}
}

class VloerSettings extends React.Component {
	state = {
		devices: [],
		settings: {},
		initialFetchDone: false,
	}
	componentWillMount() {
		this.fetch()
	}

	async fetch() {
		const res = await backend.get(`vloer/settings`)
		if (res.ok) {
			const json = res.data
			this.setState({devices: json.devices, settings: json.settings, initialFetchDone: true})
		}
	}

	createDevice = async (first = false) => {
		const res = await backend.post(`vloer/device`)
		if (res.ok) {
			const json = res.data
			this.setState({devices: json.devices})
		}
		first && this.setState({deviceV: true})
	}

	render() {
		const {createDevice} = this
		const {envId} = this.props
		const {devices, vloerV, deviceV, pauzeV, settings, initialFetchDone} = this.state
		const {roundAmt, roundType, roundEnd, end_round_amt, pauze_rules, allow_break_select} = settings

		return (
			<div className="veetwee">
				{devices.length >= 1 ? (
					<div style={{textAlign: 'center', paddingTop: 40, alignItems: 'center'}}>
						<img src="/images/eitjevloer.png" style={{width: 120, height: 120}} className="IB" />
						<h3>Environment ID: {envId} </h3>

						<div style={{alignItems: 'left', textAlign: 'left'}}>
							<p style={{fontSize: 16, fontWeight: '500', marginTop: 12, marginBottom: 8}}>{t('manageDevice')}</p>
							<div className="borderBot" style={{paddingBottom: 4}}>
								<div style={{marginBottom: 4}}>
									<p className="floatL" style={{marginTop: 4}}>
										{t('vloer25')}
									</p>
									<Button className="floatR" style={{width: 220}} onClick={() => this.setState({deviceV: true})}>
										{t('vloer19')}
									</Button>
									{deviceV && (
										<DeviceModal
											afterDel={id => this.setState({devices: devices.filter(d => d.id != id)})}
											createDevice={createDevice}
											devices={devices}
											onCancel={() => this.setState({deviceV: false})}
										/>
									)}
									<div className="clear" />
								</div>

								<div style={{marginBottom: 4}}>
									<p className="floatL" style={{marginTop: 4}}>
										{t('vloer26')}
									</p>
									<Button className="floatR" style={{width: 220}} onClick={() => this.setState({vloerV: true})}>
										{t('vloer20')}
									</Button>
									{vloerV && (
										<VloerModal
											onSucc={sett => this.setState({settings: {...settings, ...sett}, vloerV: false})}
											roundAmt={roundAmt}
											end_round_amt={end_round_amt}
											roundType={roundType}
											onCancel={() => this.setState({vloerV: false})}
										/>
									)}

									<div className="clear" />
								</div>

								<p className="floatL" style={{marginTop: 4}}>
									{t('vloer27')}
								</p>
								<Button className="floatR" style={{width: 220}} onClick={() => this.setState({pauzeV: true})}>
									{t('vloer21')}
								</Button>
								{pauzeV && (
									<PauzeModal
										onSucc={sett => this.setState({settings: {...settings, ...sett}, pauzeV: false})}
										pauze_rules={pauze_rules}
										onCancel={() => this.setState({pauzeV: false})}
									/>
								)}

								<div className="clear" />

								<div className="fRow aCen jBetween" style={{marginTop: 8}}>
									<div>
										<p>{t('allow_break_select')}</p>
										<p className="eitje-text-secondary">{t('allow_break_select2')}</p>
									</div>
									<Switch defaultChecked={allow_break_select} onChange={val => updateSettings({vloer: {allow_break_select: val}})} />
								</div>
							</div>
						</div>
					</div>
				) : (
					initialFetchDone && (
						<Placeholder name="time-clock" animation="stopwatch" docsId="6037228">
							<EitjeButton iconLeft="arrow-circle" colorSet="solid" onClick={() => createDevice(true)} t="action_button" />
						</Placeholder>
					)
				)}

				<YouTube id={getSupportYouTubeId('activate_time_clock')} />
			</div>
		)
	}
}

export default VloerSettings
