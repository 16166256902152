import {useCallback} from 'react'
import {useEditorState} from '@tiptap/react'
import {LinkFormModal, useRichTextEditorContext} from 'common/components/rich_text_editor'
import {addDefaultHttpProtocol} from 'lib/form/fields'
import {useFloatingMenu} from './hooks'
import {BarFloatingMenu} from './types'
import {FloatingMenuEditButton, FloatingMenuDeleteButton} from './components'

const COMMAND_NAME = 'link'

export const FloatingLinkMenu = () => {
	const {editor} = useRichTextEditorContext()
	const {showEdit, handleEdit, handleClose} = useFloatingMenu()
	const editorState = useEditorState({
		editor,
		selector: ({editor}) => {
			const {href: url} = editor.getAttributes(COMMAND_NAME)
			const link = getLinkDomElement(editor)
			const text = link?.innerText

			return {url, text}
		},
	})

	const handleSubmit = useCallback(({url, text}) => {
		const href = addDefaultHttpProtocol(url)

		editor
			.chain()
			.focus()
			.extendMarkRange(COMMAND_NAME)
			.setLink({href})
			.command(({tr: transaction, state}) => {
				const {$from, $to} = transaction.selection
				const start = transaction.mapping.map($from.pos)
				const end = transaction.mapping.map($to.pos)
				const linkMark = state.schema.marks.link

				transaction.insertText(text, start, end)
				transaction.removeMark(start, start + text.length, linkMark)
				transaction.addMark(start, start + text.length, linkMark.create({href}))

				return true
			})
			.run()

		handleClose()
	}, [])

	const handleUnsetLink = useCallback(() => {
		editor.chain().focus().extendMarkRange(COMMAND_NAME).unsetLink().run()
		handleClose()
	}, [])

	return (
		<>
			<BarFloatingMenu name={COMMAND_NAME} extLink={editorState.url}>
				<FloatingMenuDeleteButton onClick={handleUnsetLink} />
				<FloatingMenuEditButton onClick={handleEdit} />
			</BarFloatingMenu>
			{showEdit && <LinkFormModal initialValues={editorState} onClose={handleClose} onSubmit={handleSubmit} />}
		</>
	)
}

function getLinkDomElement(editor) {
	const {state, view} = editor
	const {from} = state.selection
	const {node} = view.domAtPos(from - 1)
	const {nodeName, parentNode} = node
	return nodeName === '#text' ? parentNode?.closest('a') : node?.closest('a')
}
