import {API} from '@eitje/easy_api'
import utils from '@eitje/web_utils'
import {getExtension, getFileExtenstionColor} from 'actions/files'
import {DeleteButton, DownloadLink} from 'common/components'
import {List} from 'components/general/list'
import {DeleteCross} from 'components/ui'
import {t} from 'initializers/i18n'
import React, {Fragment} from 'react'
import 'styles/app/components/file_items.less'

export const useFileDetails = (item, hideLink) => {
	const {created_at, name} = item
	const truncateAmount = hideLink ? 18 : 64
	const dateFormatter = hideLink ? utils.humanYearDate : utils.fullHumanYearDate
	const className = utils.makeCns('file-item-container large', !hideLink && 'extended')
	const extension = getExtension(name)
	const extensionColor = getFileExtenstionColor(extension)
	return {
		dateStr: `Uploaded: ${dateFormatter(created_at)}`,
		fileName: utils.truncateString(name, truncateAmount),
		extension,
		className,
		extensionColor,
	}
}

const FileItem = ({item, idx, id, allowRemove, kind = 'contract_holder', hideLink}) => {
	if (!item) return null
	const Wrapper = !hideLink ? Fragment : props => <a href={item.url} target="_blank" {...props} />
	const {extension, fileName, dateStr, className, extensionColor} = useFileDetails(item, hideLink)

	return (
		<Wrapper>
			<div className={className}>
				<div className="file-item-text-container">
					<p className="eitje-text-secondary">{dateStr}</p>
					<p>{fileName}</p>
				</div>

				{!hideLink && (
					<div className="file-item-links-container">
						<DownloadLink type="primary" url={item.url} />
						{allowRemove && <DeleteButton onDel={() => API.removeAttachment(kind, id, idx)} />}
					</div>
				)}
				{hideLink && (
					<div className="file-item-extention" style={{color: extensionColor}}>
						{extension}
					</div>
				)}
			</div>
		</Wrapper>
	)
}

const SmallFileItem = ({item, kind, id, idx, disableRemove, width}) => {
	if (!item) return null

	return (
		<a target="_blank" href={item.url}>
			<div className="file-item-container small" width={width}>
				<img className="file-item-icon" src="/images/web/icons/news/fileIcon.png" />
				<div className="file-item-text-container">
					<p className="file-item-title">{t('attachment')}</p>
					<p className="file-item-subtitle">{item.name}</p>
				</div>
				{!disableRemove && <DeleteCross onDel={() => API.removeAttachment(kind, id, idx)} />}
			</div>
		</a>
	)
}

const FileList = ({items = [], id, allowRemove, itemProps = {}, smallFileItem, ...props}) => {
	itemProps = {...itemProps, allowRemove, id}
	const listItem = smallFileItem ? SmallFileItem : FileItem
	return <List {...props} items={items} itemProps={itemProps} ListItem={listItem} />
}

export default FileList
