import {EitjeButton} from 'common/components'
import {t} from 'initializers/i18n'

const iconLeftProps = {size: 16}
const iconRightProps = {size: 10}

export const BaseButton = ({popoutTitle, ...rest}) => {
	return (
		<EitjeButton
			popoutTitle={popoutTitle && t(popoutTitle)}
			colorSet="initial-transparent"
			iconLibrary="font-awesome"
			height="small"
			iconLeftProps={iconLeftProps}
			iconRightProps={iconRightProps}
			{...rest}
		/>
	)
}
