import React from 'react'
import {t, mdT} from 'initializers/i18n'
import utils from '@eitje/web_utils'
import {date as dateFn} from 'initializers/date'
import {usePlanningDates, useScheduledMins} from 'cores/planning/index'
import useAvailability from 'hooks/use_availability'
import useContractMins from 'hooks/use_contract_mins'
import useUserWeek from 'cores/availability/hooks/use_user_week'
import useShared from 'hooks/use_shared'
import {DocsButton, Text, Title, Layout} from 'common/components'

const AmtWorked = ({user, date, showPopout, extraMins, extraDays}) => {
	const {envId} = useShared()
	if (_.isString(date)) date = dateFn(date)
	const {dateRange} = usePlanningDates()
	const {alwaysShowPrefShifts} = useAvailability(envId)
	const {workSchedules = []} = user
	const workSchedule = workSchedules.find(w => w.dateRange.contains(date))
	const props = {schedule: workSchedule, dateRange, user, date, extraMins, extraDays}
	const Component = !alwaysShowPrefShifts && workSchedule ? HoursInWeek : ShiftsInWeek
	return (
		<Text mini popoutBody={showPopout && <AmtWorkedExplained />}>
			<Component {...props} />
		</Text>
	)
}

const AmtWorkedExplained = () => {
	return (
		<Layout name="planning.shift_modal.helper" width={230} direction="vertical" gap={8}>
			<Title t="title" />
			<Text small t="subtitle" />
			<Layout direction="vertical">
				<Text bold t="block2_title" />
				<Text small> {mdT('planning.shift_modal.helper.block2_subtitle')} </Text>
			</Layout>
			<Layout direction="vertical">
				<Text bold t="block3_title" />
				<Text small t="block3_subtitle" />
			</Layout>
			<DocsButton width="full" to="6009887" height="small">
				{t('common.read_more_about_feature')}
			</DocsButton>
		</Layout>
	)
}

const HoursInWeek = ({schedule, user, dateRange, extraMins = 0}) => {
	const {totalMins} = useContractMins(schedule.id)
	const {totalMins: _scheduledMins} = useScheduledMins({user, dateRange})
	const scheduledMins = _scheduledMins + extraMins
	const isNegative = scheduledMins > totalMins

	return (
		<Text mini mediumRed={isNegative}>
			{utils.minToTimeString(scheduledMins)} / {utils.minToTimeString(totalMins)}
		</Text>
	)
}
const ShiftsInWeek = ({user, date, dateRange, extraMins = 0, extraDays = 0}) => {
	const userWeek = useUserWeek({id: user.id, date}) || {}
	const {preferred_shifts} = userWeek
	const {totalDays, totalMins: _totalMins} = useScheduledMins({user, dateRange})
	const totalMins = _totalMins + extraMins
	const _totalDays = totalDays + extraDays
	const isNegative = utils.exists(preferred_shifts) && _totalDays > preferred_shifts
	return (
		<Text mini mediumRed={isNegative}>
			{_totalDays} / {utils.exists(userWeek.preferred_shifts) ? userWeek.preferred_shifts : t('common.unknown')}{' '}
			{totalMins > 0 && `(${utils.minToTimeString(totalMins)})`}
		</Text>
	)
}

export default AmtWorked
