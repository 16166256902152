import i18n, {languages} from 'initializers/i18n'
import {NAMESPACE} from '../constants'
import {performColumnAction} from '../extensions/columns'

// Helper function to handle editor actions by action name
const performAction = (action, options) => editor => {
	return editor.chain().focus()[action](options).run()
}

// names and commandFns are used by tiptap internally
const BASE_COMMANDS = [
	{
		name: 'paragraph',
		icon: 'text',
		aliases: ['text', 'body'],
		commandFn: performAction('setParagraph'),
	},
	{
		name: 'heading1',
		icon: 'h1',
		aliases: ['h1'],
		commandFn: performAction('toggleHeading', {level: 1}),
		isActive: editor => editor.isActive('heading', {level: 1}),
	},
	{
		name: 'heading2',
		icon: 'h2',
		aliases: ['h2'],
		commandFn: performAction('toggleHeading', {level: 2}),
		isActive: editor => editor.isActive('heading', {level: 2}),
	},
	{
		name: 'heading3',
		icon: 'h3',
		aliases: ['h3'],
		commandFn: performAction('toggleHeading', {level: 3}),
		isActive: editor => editor.isActive('heading', {level: 3}),
	},
	{
		name: 'bold',
		icon: 'bold',
		commandFn: performAction('toggleBold'),
	},
	{
		name: 'italic',
		icon: 'italic',
		commandFn: performAction('toggleItalic'),
	},
	{
		name: 'strike',
		icon: 'strikethrough',
		commandFn: performAction('toggleStrike'),
	},
	{
		name: 'underline',
		icon: 'underline',
		commandFn: performAction('toggleUnderline'),
	},
	{
		name: 'bulletList',
		icon: 'list-bulleted',
		aliases: ['ul'],
		commandFn: performAction('toggleBulletList'),
	},
	{
		name: 'orderedList',
		icon: 'list-numbered',
		aliases: ['ol'],
		commandFn: performAction('toggleOrderedList'),
	},
	{
		name: 'blockquote',
		icon: 'quote',
		commandFn: performAction('toggleBlockquote'),
	},
	{
		name: 'horizontalRule',
		icon: 'horizontal-line',
		aliases: ['hr'],
		commandFn: performAction('setHorizontalRule'),
	},
	{
		name: 'image',
		icon: 'picture',
		aliases: ['img'],
		commandFn: editor => editor.commands.setImageUpload(),
	},
	{
		name: 'youtube',
		icon: 'film',
		commandFn: editor => editor.commands.setYouTubeModal(),
	},
	{
		name: 'link',
		icon: 'link',
		commandFn: editor => editor.commands.setLinkModal(),
	},
	{
		name: 'table',
		icon: 'table',
		commandFn: performAction('insertTable', {rows: 3, cols: 3, withHeaderRow: false}),
		isActive: editor =>
			editor.isActive('table') || editor.isActive('tableRow') || editor.isActive('tableHeader') || editor.isActive('tableCell'),
	},
	{
		name: 'textStyle',
		commandFn: color => performAction('setColor', color),
		isOptionActive: (editor, color) =>
			editor.isActive('textStyle', {color: color.toLowerCase()}) || editor.isActive('textStyle', {color: color.toUpperCase()}),
		options: [
			{
				name: 'black',
				icon: 'dot-black',
				value: Colors.black,
			},
			{
				name: 'white',
				icon: 'dot-outline-black', // using this icon, since a white icon would not be visible
				value: Colors.white,
			},
			{
				name: 'blue',
				icon: 'dot-blue',
				value: Colors.blue,
			},
			{
				name: 'purple',
				icon: 'dot-purple',
				value: Colors.purple,
			},
			{
				name: 'red',
				icon: 'dot-red',
				value: Colors.red,
			},
			{
				name: 'orange',
				icon: 'dot-orange',
				value: Colors.orange,
			},
			{
				name: 'yellow',
				icon: 'dot-yellow',
				value: Colors.yellow,
			},
			{
				name: 'green',
				icon: 'dot-olive',
				value: Colors.olive,
			},
			{
				name: 'grey',
				icon: 'dot-grey',
				value: Colors.grey,
			},
			{
				name: 'brown',
				icon: 'dot-brown',
				value: Colors.brown,
			},
		],
	},
	{
		name: 'highlight',
		commandFn: color => performAction('toggleHighlight', {color}),
		isOptionActive: (editor, color) => editor.isActive('highlight', {color}),
		options: [
			{
				name: 'none',
				icon: 'dot-outline-black',
				commandFn: performAction('unsetHighlight'),
			},
			{
				name: 'blue',
				icon: 'dot-outline-blue',
				value: Colors.midtoneBlue,
			},
			{
				name: 'purple',
				icon: 'dot-outline-purple',
				value: Colors.midtonePurple,
			},
			{
				name: 'red',
				icon: 'dot-outline-red',
				value: Colors.midtoneRed,
			},
			{
				name: 'yellow',
				icon: 'dot-outline-yellow',
				value: Colors.midtoneYellow,
			},
			{
				name: 'orange',
				icon: 'dot-outline-orange',
				value: Colors.midtoneOrange,
			},
			{
				name: 'green',
				icon: 'dot-outline-olive',
				value: Colors.midtoneOlive,
			},
			{
				name: 'grey',
				icon: 'dot-outline-grey',
				value: Colors.midtoneGrey,
			},
			{
				name: 'brown',
				icon: 'dot-outline-brown',
				value: Colors.midtoneBrown,
			},
		],
	},
	{
		name: 'textAlign',
		commandFn: value => performAction('setTextAlign', value),
		isOptionActive: (editor, value) => editor.isActive({textAlign: value}),
		options: [
			{
				name: 'left',
				icon: 'align-left',
				value: 'left',
			},
			{
				name: 'center',
				icon: 'align-center',
				value: 'center',
			},
			{
				name: 'right',
				icon: 'align-right',
				value: 'right',
			},
			{
				name: 'justify',
				icon: 'align-justify',
				value: 'justify',
			},
		],
	},
	{
		name: 'taskList',
		icon: 'checkbox-checked',
		aliases: ['todo'],
		commandFn: performAction('toggleTaskList'),
	},
	{
		name: 'undo',
		icon: 'arrow-circle-left',
		commandFn: performAction('undo'),
	},
	{
		name: 'redo',
		icon: 'arrow-circle-right',
		commandFn: performAction('redo'),
	},
	{
		name: 'two_columns',
		aliases: ['cols'],
		icon: 'column-2',
		commandFn: performColumnAction(2),
	},
	{
		name: 'three_columns',
		aliases: ['cols'],
		icon: 'column-3',
		commandFn: performColumnAction(3),
	},
]

const getTransKey = (text, suffix) => `${NAMESPACE}.${_.snakeCase(text)}${suffix ? `.${suffix}` : ''}`

const getIsActiveFn = ({name, isActive, options, isOptionActive}) => {
	// custom isActive function defined in command taxonomy
	if (isActive) return isActive

	// for option type commands we return a function that checks if one of the options is active
	if (isOptionActive) return editor => options.find(({value}) => isOptionActive(editor, value))

	// else we can just use the default tiptap isActive function
	return editor => editor.isActive(name)
}

const getOptions = ({options, isOptionActive, commandFn}) =>
	options?.map(({icon, name, value, commandFn: customCommandFn}) => ({
		icon,
		label: getTransKey(name),
		commandFn: customCommandFn ? customCommandFn : commandFn(value),
		value,
		isActive: editor => isOptionActive?.(editor, value),
	}))

const getAliasesFromName = ({name}) => languages.map(lng => i18n.t(getTransKey(name, 'label'), {lng}))

const getIsDisabled = (editor, commandName) => typeof editor.can()[commandName] === 'function' && !editor.can()[commandName]()

export const COMMANDS = BASE_COMMANDS.reduce((acc, command) => {
	const {name, aliases = []} = command
	const label = getTransKey(name, 'label')
	const options = getOptions(command)
	const isActive = getIsActiveFn(command)
	const nameAliases = getAliasesFromName(command)

	acc[name] = {
		...command,
		label,
		isActive,
		...(options && {options}),
		aliases: [...aliases, ...nameAliases],
		isDisabled: editor => getIsDisabled(editor, command.name),
	}

	return acc
}, {})
