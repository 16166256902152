import Add from './index'
import useQueryParams from 'hooks/use_query_params'
import {useHasRole} from 'hooks'

export const New = () => {
	const queryParams = useQueryParams()

	let initial = {...queryParams}
	if (initial.date) {
		initial.dates = [initial.date]
	}

	const hasRole = useHasRole('uren_schrijven')

	return <Add initialValues={initial} hasRole={hasRole} />
}
