import utils from '@eitje/web_utils'
import {MultiAction as _MultiAction} from 'cores/planning/components'
import {TABLE_INFO} from './constants'
import {columns} from './columns'
import {useAdvancedTable, AdvancedTable, getSelectedRows} from 'common/components/advanced_table'
import {useFilterFooterContext, CreateButton, usePageContext} from 'common/components'
import {useHasRole} from 'hooks'
import {ModalLink} from 'components/routing'
import {Fragment} from 'react'

export * from './constants'

export const PlanningAdvancedTable = () => {
	const {filteredItems: shifts, filteredOutAmount, clearFilters, envs} = useFilterFooterContext()

	// Unlike the time registration AdvancedTable, the user may also see rows/ shifts which it may not edit. Therefore only selectable
	// rows should have an active checkbox. But when no rows are selectable, we may simple not show the checkboxes altogether.
	const multiEdit = useHasRole('manager', envs.ids())

	const placeholderProps = {
		animation: 'clock',
		name: 'planning_table',
		supportFilmId: 'planning_variants',
		docsId: '9909529',
		// Skip create shift modal for now... since you can't select a date in the modal AND there is no way to deduce the shift's
		// date, we can't set it... When creating the new shift modal, we should change this.

		// children: <CreateButton modalLink="/shifts/new" t="action_button" />,
	}

	const tableData = useAdvancedTable({
		data: shifts,
		initialPinning: ['user_id', 'status', 'date'],
		secondarySort: 'date',
		columns,
		TABLE_INFO,
		clearFilters,
		filteredOutAmount,
	})

	const showMultiAction = getSelectedRows(tableData).length > 0

	return (
		<>
			<AdvancedTable data={tableData} RowWrapper={RowWrapper} multiEdit={multiEdit} placeholderProps={placeholderProps} />
			{showMultiAction && <MultiAction tableData={tableData} />}
		</>
	)
}

const RowWrapper = ({row, children, ...rest}) => {
	const {id, environment_id} = row.original
	if (utils.exists(row.subRows)) return children

	const hasRole = useHasRole('manager', environment_id)
	const Wrapper = hasRole ? ModalLink : Fragment

	return <Wrapper to={`/planning_shifts/${id}`} {...rest} children={children} />
}

const MultiAction = ({tableData}) => {
	const close = () => tableData.resetRowSelection()
	const selectedRows = getSelectedRows(tableData)
	const shiftIds = selectedRows.map(r => r.original.id)

	return <_MultiAction shiftIds={shiftIds} close={close} />
}
