import React, {forwardRef, useCallback, useEffect, useRef, useState, useImperativeHandle} from 'react'
import {Layout, Text} from 'common/components'
import {MenuItem} from '../menu_item'

const inlineStyles = {background: Colors.white, scrollbarWidth: 'thin'}

// Custom component for slash command extension, copied from the tiptap private example repository.
// Added eitje react components, rest of logic was directly copied from
// https://github.com/ueberdosis/tiptap-templates/tree/main/templates/next-block-editor-app/src/extensions/SlashCommand.
export const SlashMenu = forwardRef((props, ref) => {
	const scrollContainer = useRef(null)
	const activeItem = useRef(null)
	const [selectedGroupIndex, setSelectedGroupIndex] = useState(0)
	const [selectedCommandIndex, setSelectedCommandIndex] = useState(0)

	// Anytime the groups change, i.e. the user types to narrow it down, we want to
	// reset the current selection to the first menu item
	useEffect(() => {
		setSelectedGroupIndex(0)
		setSelectedCommandIndex(0)
	}, [props.items])

	const selectItem = useCallback(
		(groupIndex, commandIndex) => {
			const command = props.items[groupIndex].commands[commandIndex]
			props.command(command)
		},
		[props],
	)

	useImperativeHandle(ref, () => ({
		onKeyDown: ({event}) => {
			if (event.key === 'ArrowDown') {
				if (!props.items.length) {
					return false
				}

				const commands = props.items[selectedGroupIndex].commands

				let newCommandIndex = selectedCommandIndex + 1
				let newGroupIndex = selectedGroupIndex

				if (commands.length - 1 < newCommandIndex) {
					newCommandIndex = 0
					newGroupIndex = selectedGroupIndex + 1
				}

				if (props.items.length - 1 < newGroupIndex) {
					newGroupIndex = 0
				}

				setSelectedCommandIndex(newCommandIndex)
				setSelectedGroupIndex(newGroupIndex)

				return true
			}

			if (event.key === 'ArrowUp') {
				if (!props.items.length) {
					return false
				}

				let newCommandIndex = selectedCommandIndex - 1
				let newGroupIndex = selectedGroupIndex

				if (newCommandIndex < 0) {
					newGroupIndex = selectedGroupIndex - 1
					newCommandIndex = props.items[newGroupIndex]?.commands.length - 1 || 0
				}

				if (newGroupIndex < 0) {
					newGroupIndex = props.items.length - 1
					newCommandIndex = props.items[newGroupIndex].commands.length - 1
				}

				setSelectedCommandIndex(newCommandIndex)
				setSelectedGroupIndex(newGroupIndex)

				return true
			}

			if (event.key === 'Enter') {
				if (!props.items.length || selectedGroupIndex === -1 || selectedCommandIndex === -1) {
					return false
				}

				selectItem(selectedGroupIndex, selectedCommandIndex)

				return true
			}

			return false
		},
	}))

	useEffect(() => {
		if (activeItem.current && scrollContainer.current) {
			const offsetTop = activeItem.current.offsetTop
			const offsetHeight = activeItem.current.offsetHeight

			scrollContainer.current.scrollTop = offsetTop - offsetHeight
		}
	}, [selectedCommandIndex, selectedGroupIndex])

	const createCommandClickHandler = useCallback(
		(groupIndex, commandIndex) => {
			return () => {
				selectItem(groupIndex, commandIndex)
			}
		},
		[selectItem],
	)

	if (!props.items.length) {
		return null
	}

	return (
		<Layout ref={scrollContainer} direction="vertical" border borderRadius maxHeight={400} width={200} style={inlineStyles} gap={0}>
			{props.items.map((group, groupIndex) => (
				<React.Fragment key={group.label}>
					<Layout padding="10 12" width="full" initialBackground={Colors.lightGrey} hasHover={false} colorSet>
						<Text bold>{group.label}</Text>
					</Layout>
					{group.commands.map(({label, icon}, i) => {
						const active = selectedGroupIndex === groupIndex && selectedCommandIndex === i
						const ref = active ? activeItem : null
						const borderBottom = i !== group.commands.length - 1
						const handleClick = createCommandClickHandler(groupIndex, i)

						return (
							<MenuItem key={label} ref={ref} active={active} onClick={handleClick} icon={icon} t={label} borderBottom={borderBottom} />
						)
					})}
				</React.Fragment>
			))}
		</Layout>
	)
})
