import {Document as ReactPDFDocument, Page} from 'react-pdf/dist/esm/entry.webpack'
import {Layout, Placeholder} from 'common/components'
import {usePdfViewerContext} from '../context'
import {useDocumentWidth} from './hooks'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'

const OPTIONS = {
	cMapUrl: 'cmaps/', // support for non-latin characters
	standardFontDataUrl: 'standard_fonts/', // support PDFs using standard fonts (deprecated in PDF 1.5, but still around)
}

const inlineStyles = {
	overflow: 'auto',
	scrollbarColor: `${Colors.grey} transparent`,
	scrollbarWidth: 'thin',
}

export const Document = () => {
	const {documentContainerRef, pdf, pageCount, onItemClick, onLoadSuccess, pageRefs} = usePdfViewerContext()
	const {width, updateWidth} = useDocumentWidth(documentContainerRef) // Page component needs a set width to render the AnnotationLayer correctly

	return (
		<Layout
			ref={documentContainerRef}
			className="pdf-document"
			direction="vertical"
			horizontal="center"
			width="full"
			gap={0}
			grow
			style={inlineStyles}
		>
			<ReactPDFDocument
				error={PdfErrorPlaceholder}
				noData={NoPdfPlaceholder}
				loading={LoadingPdfPlaceholder}
				file={pdf}
				onLoadSuccess={onLoadSuccess}
				onItemClick={onItemClick}
				options={OPTIONS}
			>
				{_.times(pageCount, index => {
					const page = index + 1
					return (
						<Page
							key={`page_${page}`}
							inputRef={pageElement => (pageRefs.current[index] = pageElement)}
							pageNumber={page}
							width={width}
							onRenderSuccess={updateWidth}
							renderTextLayer={false}
							loading={null}
						/>
					)
				})}
			</ReactPDFDocument>
		</Layout>
	)
}

const NoPdfPlaceholder = () => <Placeholder name="no_pdf_provided" animation="notebook" />

const PdfErrorPlaceholder = () => <Placeholder name="pdf_load_error" animation="notebook" />

const LoadingPdfPlaceholder = () => <Placeholder name="pdf_loading" animation="notebook" />
