import {ModalWithNewForm} from 'components/form/new_modal_form'
import {Input} from '@eitje/form-fields-web'
import {Info} from 'models'
import {useParams} from 'hooks'
import {history} from 'index'

const modalProps = {
	oneFieldPerFormRow: true,
}

export const EditArticle = () => {
	const {id} = useParams()
	const article = Info.find(id)

	const handleSubmit = async data => await article.update(data)

	return (
		<ModalWithNewForm
			name="edit_article"
			width={450}
			initialValues={article}
			onSubmit={handleSubmit}
			afterSubmit={history.goBack}
			modalProps={modalProps}
		>
			<Input field="title" required maxLength={75} />
		</ModalWithNewForm>
	)
}
