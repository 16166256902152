import {useLocalValue, useHasRole, useHasRoleInCurrentEnv} from 'hooks'
import {getPlannedHours} from 'cores/planning/helpers'
import {WeatherDay} from 'models'
import {NAMESPACE, useCheckboxLeft} from '.'
import {t} from 'initializers/i18n'
import {useState} from 'react'
import usePlacesAutocomplete from 'hooks/use_places_autocomplete'
import {SubmitButton, EitjeButton, useFilterFooterContext, Layout} from 'common/components'
import useEnvSetting from 'hooks/use_env_setting'
import {saveLocation} from 'actions/environment'
import {useNewOutsideClick} from 'hooks/use_outside_click'
import './styles/panel_content.less'
import {useEditableShifts} from 'cores/planning/hooks/use_editable_shifts'

function useWeather(date) {
	const {city} = WeatherDay.find({date})
	const show = useLocalValue('planningShowWeather')
	const value = `${t(`${NAMESPACE}.weather_in`)} ${city}`

	return show && city && value
}

function useTotalTimes(date) {
	const show = useLocalValue('planningShowPlannedTimes')
	const {filteredItems: weekShifts} = useFilterFooterContext()
	const {timeStringWithText} = getPlannedHours(weekShifts)

	return show && timeStringWithText
}

const SetWeatherLocation = () => {
	const location = useEnvSetting('info', 'city')
	const isManager = useHasRoleInCurrentEnv('manager')

	// once the location is set, hide the option
	if (!isManager || location) return null

	return <InnerSetWeatherLocation location={location} />
}

const InnerSetWeatherLocation = ({location}) => {
	const [formV, setFormV] = useState(false)
	const {placesInput, placesData} = usePlacesAutocomplete({strict: false, neededComponents: ['locality']})
	const submit = () => saveLocation(placesData)

	useNewOutsideClick({onClick: () => setFormV(false), parentClass: 'set-weather-location'})

	return (
		<div className="set-weather-location">
			<EitjeButton
				height="mini"
				colorSet="color-bordered-outline"
				iconLeft="sun"
				onClick={() => setFormV(true)}
				t={`${NAMESPACE}.set_weather_location`}
			/>
			{formV && (
				<Layout className="set-weather-location-form" type="card" padding={12} gap={8}>
					{placesInput}
					<SubmitButton onClick={submit} colorSet="color-bordered-outline" />
				</Layout>
			)}
		</div>
	)
}

export function usePanelItems(date) {
	return [useWeather(date), <SetWeatherLocation />, useTotalTimes(date)]
}

export function usePanelCheckbox(date) {
	const {filteredItems: shifts} = useFilterFooterContext()
	return useCheckboxLeft(shifts)
}
