import {HelpButton as _HelpButton} from 'common/components'

const docsLinks = [
	{id: 6014511, t: 'write_article'},
	{id: 6022807, t: 'attach_pdf'},
]

export const HelpButton = props => {
	return <_HelpButton {...props} docsLinks={docsLinks} collection={4588528} />
}
