import {CreateButton, Placeholder} from 'common/components'
import {useHasRole} from 'hooks'

const sharedProps = {animation: 'high-five', layoutProps: {height: 'unset'}}

const EmptyTeamRow = () => {
	const manager = useHasRole('manager')
	return manager ? <ManagerPlaceholder /> : <NoRolePlaceholder />
}

const ManagerPlaceholder = () => {
	return (
		<Placeholder name="team" docsId="5967127" {...sharedProps}>
			<CreateButton modalLink="/team/new" t="action_button" />
		</Placeholder>
	)
}

const NoRolePlaceholder = () => {
	return <Placeholder name="team_for_non_manager" {...sharedProps} />
}

export default EmptyTeamRow
