import TiptapHighlight from '@tiptap/extension-highlight'

export const Highlight = TiptapHighlight.extend({
	addAttributes() {
		if (!this.options.multicolor) return {}

		return {
			color: {
				parseHTML: element => element.getAttribute('data-color') || element.style.backgroundColor,
				renderHTML: ({color = Colors.midtoneYellow}) => {
					return {
						'data-color': color,
						style: `background-color: ${color}; color: inherit`,
					}
				},
			},
		}
	},
})
