import {API} from '@eitje/easy_api'
import ActiveRecord from 'models/active_record/base'
import {t} from 'initializers/i18n'
import utils from '@eitje/web_utils'

const MAX_PDF_SIZE = 20000000 // 20 MB

class Info extends ActiveRecord {
	static tableName = 'infos'

	static async create({file, ...data}) {
		if (file?.[0]?.size >= MAX_PDF_SIZE) {
			return utils.errNotif(t('pdfTooBig1'), t('pdfTooBig2'))
		}

		const res = await super.create(data)

		// we need an article id to add the pdf content, therefore this two-stage rocket
		if (res.item && file) {
			await API.updateAsset(this.tableName, res.item.id, file)
		}

		return res
	}

	async updateAsset(file) {
		return await API.updateAsset(this.tableName, this.id, file)
	}

	async publish() {
		return await super.update({published: true})
	}

	async unpublish() {
		return await super.update({published: false})
	}

	async addAttachment(data) {
		return await API.attach(this.tableName, this.id, data)
	}

	async addPdfContent(data) {
		return await API.updateAsset(this.tableName, this.id, data)
	}

	async removeAttachment(index) {
		return await API.removeAttachment(this.tableName, this.id, index)
	}

	async updateReadCount() {
		return await API.arbitrary(this.tableName, `${this.tableName}/${this.id}/read`, {extraParams: {doNotLoad: true}})
	}
}

export default Info
