import {Layout} from 'common/components'
import {RichTextEditorProvider} from './context'
import {DocumentContent, DocumentMenuBar} from './components'
import './styles/index.less'

const inlineStyles = {overflow: 'hidden'}

export const RichTextEditor = ({initialContent, readOnly, onSave, updatedAt}) => {
	return (
		<RichTextEditorProvider initialContent={initialContent} readOnly={readOnly} updatedAt={updatedAt} onSave={onSave}>
			<Layout
				className="rich-text-editor"
				direction="vertical"
				border
				borderRadius
				initialBackground={Colors.white}
				gap={0}
				grow
				width="full"
				style={inlineStyles}
			>
				<DocumentMenuBar />
				<DocumentContent />
			</Layout>
		</RichTextEditorProvider>
	)
}

export * from './constants'
export * from './context'
export * from './components'
export * from './taxonomies'
export * from './extensions'
