import {useEffect, useState} from 'react'
import {Icon} from '@eitje/web_components'
import {EitjeDropdown, Text} from 'common/components'
import {useRichTextEditorContext, MENU_Z_INDEX} from 'common/components/rich_text_editor'
import DragHandle from '@tiptap-pro/extension-drag-handle-react'
import {useCurrentNodeActions, getLastDocNodePos} from './hooks'

export const DragHandleMenu = () => {
	const {editor, readOnly} = useRichTextEditorContext()
	const [menuOpen, setMenuOpen] = useState(false)
	const {currentNodePos, duplicateNode, deleteNode, addNode, handleNodeChange} = useCurrentNodeActions()

	useEffect(() => {
		editor.commands.setMeta('lockDragHandle', menuOpen)
	}, [menuOpen])

	const handleOpen = () => {
		setMenuOpen(true)
		if (currentNodePos < 0) return

		// activate floating menu's for links and youtube
		const lastNodePos = getLastDocNodePos(editor.state)
		const pos = Math.min(currentNodePos, lastNodePos)
		pos && editor.commands.setNodeSelection(pos)
	}
	const handleClose = () => setMenuOpen(false)

	const closeAfter = handler => {
		handler()
		handleClose()
	}

	const elements = [
		{icon: 'copy', element: <Text t="common.duplicate" />, onClick: () => closeAfter(duplicateNode)},
		{icon: 'plus', element: <Text t="common.add" />, onClick: () => closeAfter(addNode)},
		{icon: 'trash', element: <Text t="delete" />, onClick: () => closeAfter(deleteNode)},
	]

	if (readOnly) return null

	return (
		<DragHandle
			className="drag-handle-menu"
			pluginKey="dragHandleMenu"
			editor={editor}
			onNodeChange={handleNodeChange}
			tippyOptions={{
				offset: [4, 6],
				zIndex: MENU_Z_INDEX,
				arrow: false,
			}}
		>
			<EitjeDropdown elements={elements} trigger="click" open={menuOpen} layoutProps={{onClick: handleOpen}} onClose={handleClose}>
				<Icon name="drag-handle" fill={Colors.mediumGrey} size={14} />
			</EitjeDropdown>
		</DragHandle>
	)
}
