import {mergeAttributes, Node} from '@tiptap/core'
import {Plugin} from '@tiptap/pm/state'

// added for v3 infos compatability
// in v4 we don't use figures anymore
// copied from: https://github.com/ueberdosis/tiptap-templates/tree/main/templates/next-block-editor-app
export const Figure = Node.create({
	name: 'figure',
	group: 'block',
	content: 'block figcaption',
	draggable: true,
	defining: true,
	selectable: true,

	addOptions() {
		return {
			HTMLAttributes: {},
		}
	},

	parseHTML() {
		return [
			{
				tag: `figure[data-type="${this.name}"]`,
			},
			{
				tag: 'figure',
			},
		]
	},

	renderHTML({HTMLAttributes}) {
		return ['figure', mergeAttributes(HTMLAttributes, {'data-type': this.name}), 0]
	},

	addProseMirrorPlugins() {
		return [
			new Plugin({
				props: {
					handleDOMEvents: {
						// Prevent dragging child nodes from figure
						dragstart: (view, event) => {
							if (!event.target) return false

							const pos = view.posAtDOM(event.target, 0)
							const $pos = view.state.doc.resolve(pos)

							if ($pos.parent.type.name === this.type.name) {
								event.preventDefault()
							}

							return false
						},
					},
				},
			}),
		]
	},
})
