import {useItems} from './day_content'
import {useCheckboxLeft} from '.'
import {usePerWeekContext} from 'cores/time_registration'
import {useFilterFooterContext} from 'common/components'

export function usePanelItems() {
	const {dateRange} = usePerWeekContext()
	return useItems(dateRange)
}

export function usePanelCheckbox(date) {
	let {filteredItems: shifts} = useFilterFooterContext()
	return useCheckboxLeft(shifts)
}
