import {useCallback} from 'react'
import {useEditorState} from '@tiptap/react'
import {NAMESPACE, useRichTextEditorContext} from 'common/components/rich_text_editor'
import {BarFloatingMenu} from './types'
import {FloatingMenuDeleteButton} from './components'
import {TextEditorButton} from '../text_editor_button'

const COMMAND_NAME = 'image'

export const FloatingImageMenu = () => {
	const {editor} = useRichTextEditorContext()
	const {isSmall, isMedium, isLarge} = useEditorState({
		editor,
		selector: ({editor}) => {
			const {width} = editor.getAttributes(COMMAND_NAME)
			const imageStorage = editor.storage.image
			const size = Object.keys(imageStorage).find(key => imageStorage[key] === width)

			return {
				width,
				isSmall: size === 'small',
				isMedium: size === 'medium',
				isLarge: size === 'large' || !size,
			}
		},
	})

	const handleWidthChange = useCallback(value => editor.chain().focus(undefined, {scrollIntoView: false}).setImageWidth(value).run(), [])
	const onSelectSmall = () => handleWidthChange('small')
	const onSelectMedium = () => handleWidthChange('medium')
	const onSelectLarge = () => handleWidthChange('large')

	const handleDelete = useCallback(() => {
		editor.commands.deleteSelection()
	}, [])

	return (
		<BarFloatingMenu name={COMMAND_NAME}>
			<TextEditorButton t={`${NAMESPACE}.sizes.small`} onClick={onSelectSmall} active={isSmall} />
			<TextEditorButton t={`${NAMESPACE}.sizes.medium`} onClick={onSelectMedium} active={isMedium} />
			<TextEditorButton t={`${NAMESPACE}.sizes.large`} onClick={onSelectLarge} active={isLarge} />
			<FloatingMenuDeleteButton onClick={handleDelete} />
		</BarFloatingMenu>
	)
}
