import {BaseFloatingMenu} from './base_floating_menu'
import {Layout} from 'common/components'
import {MenuItem} from '../../menu_item'

export const DropdownFloatingMenu = ({name, openFn, elements}) => {
	return (
		<BaseFloatingMenu name={name} openFn={openFn}>
			<Layout className="dropdown-floating-menu" direction="vertical" gap={0} initialBackground={Colors.white} borderRadius border>
				{elements.map((element, i) => {
					const borderBottom = i !== elements.length - 1

					return <MenuItem key={element.t} borderBottom={borderBottom} {...element} />
				})}
			</Layout>
		</BaseFloatingMenu>
	)
}
