import {ListPicker as ListPickerComponent} from '@eitje/web_components'
import {buildField} from '@eitje/form-fields-web'
import {hooklessRoleMessage} from 'hooks'

export const ListPicker = buildField(ListPickerComponent, props => {
	const withClearIcon = !props.required && !props.min && props.dropdown && props.allowClear !== false

	return {
		className: 'eitje-list-picker',
		icon: !!props.dropdown,
		defaultPickerValue: props.single ? null : [],
		selectAll: false,

		// New form fields have a different key `clearIcon`, transform to easily use both the new and old `withClearIcon` interface.
		withClearIcon,
		clearIcon: withClearIcon,
	}
})

export const useListPickerItemValidation = ({roles, extraValidation, roleEnvs, orgEnvs}) => {
	// Ideally we would rolify ListPicker items differently, relying simply on the Layout's internal role validations and
	// message formatting. But this doesn't work, since the ListPicker and Layout source code resides in the component lib –
	// and is role agnostic over there – while the role enhanced web Layout is unknown to comp lib. Eventually we might do
	// some passing of components, but we should consider this for a bit to prevent overcomplicating our stupid component
	// setup further. Thus happily use this for the foreseeable future, yay!
	const itemDisabled = item => {
		const envId = (item.tableName === 'environments' && item.id) || item.environment_id
		const disabled = hooklessRoleMessage({roles, envId, roleEnvs, orgEnvs})
		const missingRole = envId && disabled
		const failedCustomValidation = extraValidation?.(item)

		return missingRole || failedCustomValidation
	}

	return {itemDisabled}
}
