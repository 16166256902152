import {Layout} from 'common/components'
import {PdfPageNavigation, PdfActionsDropdown} from '.'

// left element is conditionally shown
// and we want to make sure the right element sticks to the right side
const inlineStyles = {flexDirection: 'row-reverse'}

export const Header = () => {
	return (
		<Layout
			className="pdf-header"
			horizontal="spaceBetween"
			padding="4 6"
			borderBottom
			initialBackground={Colors.lightGrey}
			style={inlineStyles}
		>
			<PdfActionsDropdown />
			<PdfPageNavigation />
		</Layout>
	)
}
