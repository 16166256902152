import {WeekTableColumn, AddButton, useFilterFooterContext} from 'common/components'
import {useRoleMessage, useLocalValue, useDrop, useHasRole} from 'hooks'
import {PlanningShiftCell} from './planning_shift_cell'
import {AvailabilityCell} from './availability_cell'
import {SickPeriodCell} from './sick_period_cell'
import {useUserContext} from 'contexts/user'
import {useGetPlanningShiftUrlWithEnv} from 'cores/planning/helpers'
import {useEnvsContext} from 'cores/planning/pages/index/envs_provider'
import useAvShift from 'cores/availability/hooks/use_av_shift'
import utils from '@eitje/web_utils'
import {t} from 'initializers/i18n'
import {TimeRegistrationShiftCell} from 'cores/time_registration'

export const Column = ({date, ...rest}) => {
	const {shifts: planningShifts, user, timeRegistrationShifts, sickPeriods} = useUserContext()
	const dayPlanningShifts = planningShifts.where({date})
	const {multiEdit} = useEnvsContext()
	const hasPlanningShifts = dayPlanningShifts.length > 0
	const regShifts = timeRegistrationShifts.where({date})
	const hasRegShifts = regShifts.length > 0
	const availabilityShift = useAvShift({user_id: user?.id, date})
	const {teams} = useFilterFooterContext()
	const {isOver, canDrop, dropRef} = usePlanningShiftDrop({newDate: date, newUser: user, teams})
	const manager = useHasRole('manager')
	const showAvailability = useLocalValue('planningPerMemberShowUserAvailability')
	const sickPeriod = !_.isEmpty(sickPeriods) && sickPeriods.find(period => period.isOnDate(date))

	// only show availability when there are no planning and registered shifts.
	// Also don't show the availabilityCell when hovering, so that the create shift button fits well
	const availabilityCell = !hasPlanningShifts && !sickPeriod && !hasRegShifts && manager && showAvailability

	let children
	if (hasPlanningShifts) {
		children = dayPlanningShifts.map(shift => <PlanningShiftCell shift={shift} />)
	} else if (hasRegShifts) {
		children = regShifts.map(shift => <TimeRegistrationShiftCell shift={shift} disableDrag />)
	} else if (sickPeriod) {
		children = <SickPeriodCell sickPeriod={sickPeriod} />
	} else {
		children = availabilityCell && <AvailabilityCell availabilityShift={availabilityShift} />
	}

	return (
		<WeekTableColumn
			ref={dropRef}
			dragProps={{isOver, canDrop}}
			hoverChildren={!multiEdit && <CreateShiftButton date={date} />}
			hideChildrenOnHover={availabilityCell && !multiEdit}
			{...rest}
		>
			{children}
		</WeekTableColumn>
	)
}

const CreateShiftButton = ({date}) => {
	const {user} = useUserContext()
	const createShiftUrl = useGetPlanningShiftUrlWithEnv({user, date})
	const disabled = useRoleMessage('manager')

	return (
		<AddButton
			width="full"
			modalLink={createShiftUrl}
			iconLeft="plus"
			colorSet="color-bordered-color-content"
			t="records.planning_shift.name"
			minHeight="100%"
			disabled={disabled}
		/>
	)
}

export function usePlanningShiftDrop({newDate, newUser, teams}) {
	const dropFn = shift => {
		const differentDay = shift.date != newDate.format()
		const differentUser = shift.user_id != newUser?.id
		const team = teams.find(team => team.id === shift.team_id)
		const userNotInEnv = newUser && !newUser?.environment_ids.includes(team.environment_id)

		if (userNotInEnv) return utils.errorNotif({content: t('planning.not_part_of_env_warning')})
		// use `null` to update to openShift
		if (differentDay || differentUser) return shift.update({date: newDate, user_id: newUser?.id || null})
	}

	const {isOver, canDrop, dropRef} = useDrop({
		accept: `planning-shift`,
		canDrop: item => true,
		onDrop: item => dropFn(item.shift),
	})

	return {isOver, canDrop, dropRef}
}
