import {createContext, useContext, useState, useMemo} from 'react'

const JumpToMessageProviderContext = createContext()
const {Provider} = JumpToMessageProviderContext

export const JumpToMessageProvider = ({children}) => {
	const [messageId, setMessageId] = useState()

	const state = useMemo(() => ({messageId, setMessageId}), [messageId])

	return <Provider value={state} children={children} />
}

export const useJumpToMessageProviderContext = () => useContext(JumpToMessageProviderContext)
