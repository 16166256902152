import {useCallback} from 'react'
import {NAMESPACE, useRichTextEditorContext} from 'common/components/rich_text_editor'
import {DropdownFloatingMenu} from './types'

export const FloatingTableColumnMenu = () => <FloatingTableMenu type="column" />

export const FloatingTableRowMenu = () => <FloatingTableMenu type="row" />

const FloatingTableMenu = ({type}) => {
	const {editor} = useRichTextEditorContext()
	const ns = `${NAMESPACE}.table`

	// A function that determines whether the menu should be shown or not.
	// If this function returns false, the menu will be hidden, otherwise it will be shown.
	const openFn = useCallback(({view, state, from = 0}) => {
		if (!state || !editor.isActive('table')) return false
		return isGripSelected({type, view, from})
	}, [])

	const handleCommand = useCallback(command => {
		editor.chain().focus()[command]().run()
	}, [])

	const isColumn = type === 'column'
	const elements = [
		{
			icon: isColumn ? 'arrow-to-line-left' : 'arrow-to-line-up',
			t: `${ns}.add_${type}_before`,
			onClick: () => handleCommand(isColumn ? 'addColumnBefore' : 'addRowBefore'),
		},
		{
			icon: isColumn ? 'arrow-to-line-right' : 'arrow-to-line-down',
			t: `${ns}.add_${type}_after`,
			onClick: () => handleCommand(isColumn ? 'addColumnAfter' : 'addRowAfter'),
		},
		{
			icon: 'trash',
			t: `${ns}.delete_${type}`,
			onClick: () => handleCommand(isColumn ? 'deleteColumn' : 'deleteRow'),
		},
	]

	const name = `table${type.capitalize()}Menu`
	return <DropdownFloatingMenu name={name} openFn={openFn} elements={elements} />
}

function isGripSelected({type, view, from}) {
	const domAtPos = view.domAtPos(from).node
	const nodeDOM = view.nodeDOM(from)
	const tags = type === 'column' ? ['TD', 'TABLE'] : ['TR', 'TABLE']

	let container = nodeDOM || domAtPos
	while (container && !tags.includes(container.tagName)) {
		container = container.parentElement
	}

	if (!container) return false

	const grip = container.querySelector?.(`a.grip-${type}.selected`)

	return !!grip
}
