import {t} from 'initializers/i18n'

export const getShiftStatusFilter = ({withOpenShifts}) => {
	const statusItems = [
		{id: 'published', name: t('records.default.fields.published_true')},
		{id: 'unpublished', name: t('records.default.fields.published_false')},
		{id: 'trade_request', name: t('common.trade_request')},
	]

	const itemsWithOpenShifts = [...statusItems, {id: 'open_shift', name: t('records.shift.open_shift_plural')}]
	const items = withOpenShifts ? itemsWithOpenShifts : statusItems

	const filterProps = {
		noSort: true,
		name: 'status',
		items,
	}

	const filterFunction = (shift, filterValues) => {
		// Simply always return all records if none of the filters is selected.
		const noFilters = _.isEmpty(filterValues)
		if (noFilters) return true

		let filters = {}
		filterValues.each(s => (filters[s] = true))
		const {published, user_id, hasTradeRequest} = shift

		// When a type filter (trade_request or open_shift) is active, we don't always want to return every
		// published shift – but only the subset of published shifts of the selected types. However, if no type
		// filter is active, we should always show any published shift.
		const anyTypeFilter = filters.trade_request || filters.open_shift
		if (!anyTypeFilter) {
			if (filters.published && published) return true
			if (filters.unpublished && !published) return true
		}

		// Use a logical XOR to check if only one of the two publish filters is active, since no publish filters
		// being active should be the same as both being active.
		const noPublishFilter = !(filters.published !== filters.unpublished)
		const showAsTradeRequest = filters.trade_request && hasTradeRequest()
		const showAsOpenShift = filters.open_shift && !user_id

		// When a type filter is active, we should further filter them by (un)publish status if one of those
		// filters is active – or simple return all of that type if none or both of the (un)publish statuses
		// are active.
		if (showAsTradeRequest || showAsOpenShift) {
			if (noPublishFilter) return true
			if (filters.published && published) return true
			if (filters.unpublished && !published) return true
		}

		return false
	}

	return {
		filterProps,
		filterFunction,
	}
}
