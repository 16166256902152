import {HOST} from 'initializers/api'
import 'lib/redactor/article-editor.css'
import ArticleEditor from 'lib/redactor/article-editor.js'
import 'lib/redactor/imageposition.min.js'
import 'lib/redactor/imageresize.min.js'
import 'lib/redactor/inlineformat.min.js'
import 'lib/redactor/reorder.min.js'
import 'lib/redactor/style.min.js'
import 'lib/redactor/list-plugin.js'
import React, {useEffect, useRef} from 'react'
import {useLocation} from 'react-router'
import '../styles/editor.less'
// import 'styles/arx-frame.css'

export const ArticleView = ({content, id}) => {
	return (
		<div className="vertical-padding-xl" style={{width: 'inherit'}}>
			<div className={'article-viewer'}>
				<div dangerouslySetInnerHTML={{__html: content}} />
			</div>
		</div>
	)
}
