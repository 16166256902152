import {buildField} from '@eitje/form-fields-web'
import {Layout, EitjeButton, Text} from 'common/components'
import {useFileUpload} from 'hooks'

const Field = ({value, onChange, accept}) => {
	const {inputElement, handleUploadClick} = useFileUpload({onUpload: onChange, accept})
	const file = value?.[0]

	return (
		<Layout onClick={handleUploadClick}>
			{file && <Text>{file.name}</Text>}
			{!file && <EitjeButton iconLeft="upload" />}
			{inputElement}
		</Layout>
	)
}

export const FilePicker = buildField(Field, {className: 'eitje-file-picker', clearIcon: false})
