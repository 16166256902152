import {EitjeButton, Text, ListPicker} from 'common/components'
import {PopoutCard} from '@eitje/web_components'
import {t} from 'initializers/i18n'
import {NAMESPACE, useArticleContext, useTopics} from 'cores/knowledge'

export const TopicButton = () => {
	const topics = useTopics() // decorated with disabled message
	const topicIds = topics.ids()
	const {article, editDisabled} = useArticleContext()
	const {topic_ids: articleTopicsIds} = article
	// Filter articleTopicIds on the topics that are present in your Redux store, and thus allowed to see
	const accessibleTopicIds = articleTopicsIds.filter(topicId => topicIds.includes(topicId))

	const handleAddTopic = async data => await article.addRemoveAssoc({topic_ids: data}, {topic_ids: accessibleTopicIds})

	return (
		<PopoutCard
			body={
				<ListPicker
					raw
					labelField="title"
					valueField="id"
					value={accessibleTopicIds}
					items={topics}
					onChange={handleAddTopic}
					showSearch
					easyRemove={false}
				/>
			}
			bodyPadding={0}
			disabled={!!editDisabled}
			trigger="click"
			placement="bottom-start"
			maxWidth={400}
		>
			<Trigger countSuffix={accessibleTopicIds.length} disabled={editDisabled} />
		</PopoutCard>
	)
}

const Trigger = ({countSuffix, disabled}) => {
	if (countSuffix)
		return (
			<EitjeButton iconLeft="link" colorSet="color-bordered-outlined" disabled={disabled}>
				<Text countSuffix={countSuffix}>{t('records.theme.name', {count: countSuffix})}</Text>
			</EitjeButton>
		)

	return (
		<EitjeButton
			t="link_topic"
			name={NAMESPACE}
			iconLeft="exclamation-mark-circled"
			baseColor="orange"
			colorSet="color-bordered-fill"
			disabled={disabled}
		/>
	)
}
