import {useCallback} from 'react'
import {NodeViewWrapper} from '@tiptap/react'
import {LinkFormModal} from 'common/components/rich_text_editor'
import {addDefaultHttpProtocol} from 'lib/form/fields'

export const LinkNodeView = ({editor, node, deleteNode}) => {
	const handleClose = () => editor.commands.undo()

	const handleSubmit = useCallback(
		({url, text}) => {
			const href = addDefaultHttpProtocol(url)

			editor
				.chain()
				.focus()
				.extendMarkRange('link')
				.setLink({href})
				.command(({tr: transaction, state}) => {
					const {$from, $to} = transaction.selection
					const start = transaction.mapping.map($from.pos)
					const end = transaction.mapping.map($to.pos)
					const linkMark = state.schema.marks.link

					transaction.insertText(text, start, end)

					// since we are inserting a node to spawn a modal
					// we need to add one to make sure the entire text is marked
					const markEnd = start + text.length + (end - start)
					transaction.removeMark(start, markEnd, linkMark)
					transaction.addMark(start, markEnd, linkMark.create({href}))

					return true
				})
				.run()

			deleteNode()

			editor.chain().focus().selectTextblockStart().joinTextblockBackward().run()
		},
		[editor, deleteNode],
	)

	const initialValues = {
		text: node.attrs.data,
	}

	return (
		<NodeViewWrapper>
			<LinkFormModal initialValues={initialValues} onClose={handleClose} onSubmit={handleSubmit} />
		</NodeViewWrapper>
	)
}
